import type { EventClickArg } from "@fullcalendar/core";
import {
  filterEventsByCurrentUnit,
  type CalendarEvent,
  type CalendarView,
  type Location,
  buildEmptyEvents,
  UnavailableSlotType,
  defineAppointmentType,
  getCurrentDate,
  type CalendarEventType,
  getAllDaysOfWeekFromDateString,
  convertDateTimeIgnoringTimezone,
  formatIgnoringTimezone,
  isDateWithinRepetition,
  AppointmentPurpose,
  ScheduleFrequencyEnum,
} from "@repo/lib";
import { useEffect, type Dispatch, type SetStateAction, useState } from "react";
import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { differenceInMinutes, isEqual, isSameDay, isAfter } from "date-fns";
import { CalendarDayEvent } from "@/components/calendar-day-event";

const HomeCalendarDayEventFragment = graphql(/* GraphQL */ `
  fragment HomeCalendarDayEventFragment on query_root {
    agendamentos(
      where: {
        FinalidadesAgendamento: {
          codFinalidadeAgendamento: { _in: $availableAppointmentsPurpose }
        }
        _and: [{ data: { _gte: $dataInicio } }, { data: { _lte: $dataFim } }]
      }
    ) {
      codAgendamento
      data
      horaInicio
      horaFim
      nome
      confirmado
      cancelado
      cobraCancelamento
      codFinalidadeAgendamento
      pago
      AgendamentosCheckin {
        codAgendamento
      }
      FinalidadesAgendamento {
        codFinalidadeAgendamento
        taxaCobrancaAtraso
      }
      Paciente {
        telefone
        nome
        email
        codPaciente
      }
      UsuarioCompromisso {
        nome
      }
      Unidade {
        codUnidade
        nome
        sigla
        nomeLimpo
        ativo
      }
    }
    tbFechamentos(
      where: { _and: [{ data: { _gte: $dataInicio } }, { data: { _lte: $dataFim } }] }
    ) {
      codUnidade
      data
      horaFim
      horaInicio
      motivo
      codFechamento
      codTipoFechamento
      tbUnidade {
        nome
        sigla
        nomeLimpo
        codUnidade
        ativo
      }
    }
  }
`);

export const HolidaysHomeCalendarDayEventFragment = graphql(/* GraphQL */ `
  fragment HolidaysHomeCalendarDayEventFragment on query_root {
    LivanceApiFeriadosPorPeriodo(
      arg1: { dataInicio: $dataInicioToString, dataFim: $dataFimToString }
    ) {
      codFeriado
      codUnidade
      dia
      mes
      ano
      horaInicio
      horaFim
      nome
    }
  }
`);

export const TimePoliciesHomeCalendarDayEventFragment = graphql(/* GraphQL */ `
  fragment TimePoliciesHomeCalendarDayEventFragment on query_root {
    unidadesPoliticasDeHorarios(where: { ativo: { _eq: true }, politica: { _eq: 1 } }) {
      codUnidadePoliticaDeHorario
      diaSemana
      inicio
      fim
      dataInicio
      codUnidade
      Unidade {
        nome
        nomeLimpo
        codUnidade
        ativo
        sigla
      }
    }
  }
`);

export const FreeSchedulesSlotsHomeCalendarDayEventFragment = graphql(/* GraphQL */ `
  fragment FreeSchedulesSlotsHomeCalendarDayEventFragment on query_root {
    LivanceApiUsuarioAgendaAtivosPorUsuario(arg1: { codUsuario: $codUsuario }) {
      dataInicio
      dataFim
      inicio
      fim
      codTipoAgendaRepeticao
      usuariosAgendasUnidades {
        codUnidade
      }
      usuariosAgendasDiasSemanas {
        diaSemana
      }
    }
  }
`);

interface HomeCalendarDayEventProps {
  currentDate: string;
  currentLocation: Location;
  currentCalendarView: CalendarView;
  setCurrentEventSelected: Dispatch<SetStateAction<CalendarEvent | undefined>>;
  handleOpenRemoveUnavailableSlot: (unavailableSlotId: number) => void;
  handleOpenAppointmentDetailsDrawer: (appointmentId: number) => void;
  homeCalendarDayEventFragmentData: FragmentType<typeof HomeCalendarDayEventFragment>;
  holidaysHomeCalendarDayEventFragmentData?: FragmentType<
    typeof HolidaysHomeCalendarDayEventFragment
  >;
  timePoliciesHomeCalendarDayEventFragmentData: FragmentType<
    typeof TimePoliciesHomeCalendarDayEventFragment
  >;
  freeScheduleSlotsHomeCalendarDayEventFragmentData: FragmentType<
    typeof FreeSchedulesSlotsHomeCalendarDayEventFragment
  >;
}

const DEFAULT_CALENDAR_START_HOUR = 7;
const DEFAULT_CALENDAR_END_HOUR = 23;

export const HomeCalendarDayEvent = ({
  currentDate,
  currentCalendarView,
  setCurrentEventSelected,
  handleOpenRemoveUnavailableSlot,
  handleOpenAppointmentDetailsDrawer,
  currentLocation,
  homeCalendarDayEventFragmentData,
  holidaysHomeCalendarDayEventFragmentData,
  timePoliciesHomeCalendarDayEventFragmentData,
  freeScheduleSlotsHomeCalendarDayEventFragmentData,
}: HomeCalendarDayEventProps): JSX.Element => {
  const [hasScrolledToNowIndicator, setHasScrolledToNowIndicator] = useState(false);
  const router = useRouter();

  const { showCancelled } = useSearch({
    from: "/",
  });

  const { tbFechamentos: closedSchedules, agendamentos } = useFragment(
    HomeCalendarDayEventFragment,
    homeCalendarDayEventFragmentData,
  );

  const holidayFragmentResult = useFragment(
    HolidaysHomeCalendarDayEventFragment,
    holidaysHomeCalendarDayEventFragmentData,
  );

  const timePoliciesFragmentResult = useFragment(
    TimePoliciesHomeCalendarDayEventFragment,
    timePoliciesHomeCalendarDayEventFragmentData,
  );

  const freeScheduleSlotsFragmentResult = useFragment(
    FreeSchedulesSlotsHomeCalendarDayEventFragment,
    freeScheduleSlotsHomeCalendarDayEventFragmentData,
  );

  const isAllUnitsOptionSelected = currentLocation.codUnidade === 0;

  const currentDateObject = convertDateTimeIgnoringTimezone(currentDate);

  const buildAppointmentsEvents = (): CalendarEvent[] => {
    const appointments = showCancelled
      ? agendamentos.filter((a) => a.cancelado)
      : agendamentos.filter((a) => !a.cancelado);

    const appointmentEvents = appointments.map((appointment) => {
      return {
        id: `appointment-${appointment.codAgendamento.toString()}`,
        type: defineAppointmentType(appointment),
        title: appointment.nome,
        appointmentType: appointment.UsuarioCompromisso?.nome,
        start: convertDateTimeIgnoringTimezone(appointment.data, appointment.horaInicio),
        end: convertDateTimeIgnoringTimezone(appointment.data, appointment.horaFim),
        appointmentPurposeId: appointment.codFinalidadeAgendamento,
        appointment,
        unit: {
          id: appointment.Unidade.codUnidade,
          name: appointment.Unidade.nome,
          acronym: appointment.Unidade.sigla,
        },
      } as CalendarEvent;
    });

    return appointmentEvents;
  };

  const buildUnavailableSlotsEvents = (): CalendarEvent[] => {
    const filteredUnavailableSlots = closedSchedules.filter((unavailableSlot) => {
      const manualUnavailableSlot = 2;
      if (
        currentLocation.codUnidade === 0 &&
        unavailableSlot.codTipoFechamento === manualUnavailableSlot
      ) {
        return true;
      }

      return (
        unavailableSlot.codUnidade === null ||
        unavailableSlot.codUnidade === currentLocation.codUnidade
      );
    });

    const unavailableSlotEvents = filteredUnavailableSlots.map(
      (unavailableSlotElement) =>
        ({
          id: `unavailable-slot-${unavailableSlotElement.codFechamento.toString()}`,
          type: "closed",
          title: unavailableSlotElement.motivo ?? "",
          appointmentType: "Fechamento",
          start: convertDateTimeIgnoringTimezone(
            unavailableSlotElement.data,
            unavailableSlotElement.horaInicio,
          ),
          end: convertDateTimeIgnoringTimezone(
            unavailableSlotElement.data,
            unavailableSlotElement.horaFim,
          ),
          appointment: null,
          unit: {
            id: unavailableSlotElement.codUnidade,
            name: unavailableSlotElement.tbUnidade?.nome,
            acronym: unavailableSlotElement.tbUnidade?.sigla,
          },
        }) as CalendarEvent,
    );

    return unavailableSlotEvents;
  };

  const buildHolidaysEvents = (): CalendarEvent[] => {
    const currentYear = new Date().getFullYear();

    const holidays = holidayFragmentResult?.LivanceApiFeriadosPorPeriodo ?? [];
    const holidaysEvents = [] as CalendarEvent[];

    for (const holiday of holidays) {
      if (!holiday) continue;

      if (isAllUnitsOptionSelected && holiday.codUnidade !== null) {
        continue;
      }

      if (
        currentLocation.codUnidade !== 0 &&
        holiday.codUnidade !== currentLocation.codUnidade
      ) {
        continue;
      }

      if (holiday.ano && holiday.ano < currentYear) {
        continue;
      }

      const year = holiday.ano ?? currentYear;

      const start = convertDateTimeIgnoringTimezone(
        `${year}-${holiday.mes.toString().padStart(2, "0")}-${holiday.dia}`,
        holiday.horaInicio,
      );
      const end = convertDateTimeIgnoringTimezone(
        `${year}-${holiday.mes.toString().padStart(2, "0")}-${holiday.dia}`,
        holiday.horaFim,
      );

      holidaysEvents.push({
        id: `holiday-${holiday.codFeriado.toString()}`,
        type: "holiday",
        title: holiday.nome,
        appointmentType: "Unidade Fechada",
        start,
        end,
        appointment: null,
        unit: {
          id: holiday.codUnidade ?? 0,
          name: currentLocation.nome,
          acronym: currentLocation.sigla ?? "",
        },
      });
    }

    return holidaysEvents;
  };

  const buildTimePoliciesEvents = (): CalendarEvent[] => {
    const timePolicies = timePoliciesFragmentResult.unidadesPoliticasDeHorarios;

    const timePoliciesEvents = [] as CalendarEvent[];
    const allDaysInCurrentWeek = getAllDaysOfWeekFromDateString(currentDate);

    for (const timePolicy of timePolicies) {
      const isCurrentDateBeforeTimePolicyBeginning =
        currentDateObject.getTime() <
        convertDateTimeIgnoringTimezone(timePolicy.dataInicio).getTime();

      if (
        isCurrentDateBeforeTimePolicyBeginning ||
        timePolicy.codUnidade !== currentLocation.codUnidade
      ) {
        continue;
      }

      const date = allDaysInCurrentWeek.find((d) => d.getDay() === timePolicy.diaSemana);

      if (!date) continue;

      const start = convertDateTimeIgnoringTimezone(date, timePolicy.inicio);
      const end = convertDateTimeIgnoringTimezone(date, timePolicy.fim);

      timePoliciesEvents.push({
        type: "timePolicy",
        id: `timePolicy-${timePolicy.codUnidadePoliticaDeHorario.toString()}`,
        title: "",
        appointmentType: "Indisponibilidade",
        start,
        end,
        appointment: null,
        unit: {
          id: currentLocation.codUnidade,
          name: currentLocation.nome,
          acronym: currentLocation.sigla ?? "",
        },
      });
    }

    return timePoliciesEvents;
  };

  const buildFreeWeekSlotsEvents = (): CalendarEvent[] => {
    const MINIMUM_FREE_SLOT_DURATION = 15;
    const now = getCurrentDate();
    const selectedWeekDay = parseInt(formatIgnoringTimezone(currentDateObject, "i"));

    let freeSlotsEvents =
      freeScheduleSlotsFragmentResult.LivanceApiUsuarioAgendaAtivosPorUsuario;

    freeSlotsEvents = freeSlotsEvents?.reduce(
      (filteredSlots, slot) => {
        const matchesUnit =
          currentLocation.codUnidade === 0 ||
          slot?.usuariosAgendasUnidades?.some(
            (unit) => unit.codUnidade === currentLocation.codUnidade,
          );
        const matchesWeekDay = slot?.usuariosAgendasDiasSemanas?.some(
          (weekDay) => selectedWeekDay === weekDay.diaSemana,
        );

        const matchesDateRepetition = slot?.usuariosAgendasDiasSemanas?.some(() =>
          isDateWithinRepetition(
            new Date(slot.dataInicio),
            currentDateObject,
            slot.codTipoAgendaRepeticao,
          ),
        );

        if (matchesUnit && matchesWeekDay && matchesDateRepetition) {
          filteredSlots.push(slot);
        }

        return filteredSlots;
      },
      [] as typeof freeSlotsEvents,
    );

    const calendarEvents =
      freeSlotsEvents?.map((slot, index) => {
        if (!slot) return;

        const scheduleHasRepetition =
          slot.codTipoAgendaRepeticao !== ScheduleFrequencyEnum.NaoRepetir.valueOf();
        const scheduleHasEndDate = Boolean(slot.dataFim);

        let scheduleDate = currentDate;

        const isUniqueDaySchedule = scheduleHasEndDate && !scheduleHasRepetition;

        if (isUniqueDaySchedule) {
          scheduleDate = slot.dataInicio;
        } else if (scheduleHasEndDate) {
          scheduleDate = String(slot.dataFim);
        }

        return {
          id: `week-slot-${index}`,
          type: "weekSlot",
          title: "",
          appointmentType: "",
          start: convertDateTimeIgnoringTimezone(scheduleDate, slot.inicio),
          end: convertDateTimeIgnoringTimezone(scheduleDate, slot.fim),
          appointment: null,
          unitId: currentLocation.codUnidade,
          unit: {
            id: currentLocation.codUnidade,
            name: currentLocation.nome,
            acronym: currentLocation.sigla,
          },
          display: "background",
          classNames: ["fc-weekslot-event"],
        } as CalendarEvent;
      }) ?? [];

    return calendarEvents
      .filter((event): event is CalendarEvent => event !== undefined)
      .filter((event) => isAfter(event.start, now))
      .filter((event: CalendarEvent) => {
        const dateDiff = differenceInMinutes(event.end, event.start);
        return dateDiff >= MINIMUM_FREE_SLOT_DURATION;
      });

  };

  const allCalendarEvents: CalendarEvent[] = [
    ...buildAppointmentsEvents(),
    ...buildUnavailableSlotsEvents(),
    ...buildHolidaysEvents(),
    ...buildTimePoliciesEvents(),
    ...buildFreeWeekSlotsEvents(),
  ];

  const goToUnavailableSlotCreatePage = (
    date: string,
    start?: string,
    end?: string,
  ): Promise<void> =>
    router.navigate({
      to: "/calendar/unavailable-slots/create",
      search: {
        date,
        locationId:
          currentLocation.codUnidade === 0 ? undefined : currentLocation.codUnidade,
        start,
        end,
      },
    });

  const goToNewAppointmentPage = (date: string): Promise<void> =>
    router.navigate({
      to: "/appointment/create",
      search: {
        date,
        locationId:
          currentLocation.codUnidade === 0 ? undefined : currentLocation.codUnidade,
      },
    });

  const calendarEventsWithEmpty = buildEmptyEvents(
    allCalendarEvents,
    currentDateObject,
    currentLocation,
  );

  const calendarEventsFiltered =
    currentCalendarView === "dayGridWeek"
      ? calendarEventsWithEmpty.filter((e) => e.type !== "none")
      : calendarEventsWithEmpty;

  const events = filterEventsByCurrentUnit(
    calendarEventsFiltered,
    currentLocation.codUnidade,
  );

  const handleClosedEvent = (eventFound: CalendarEvent): void => {
    const unavailableSlotFound = closedSchedules.find(
      (c) => c.codFechamento === parseInt(eventFound.id.replace("unavailable-slot-", "")),
    );

    const removableUnavailableSlot = [
      UnavailableSlotType.CreatedByUser.valueOf(),
      UnavailableSlotType.CreatedByMovement.valueOf(),
    ];

    if (
      unavailableSlotFound &&
      removableUnavailableSlot.includes(unavailableSlotFound.codTipoFechamento)
    ) {
      setCurrentEventSelected(eventFound);
      handleOpenRemoveUnavailableSlot(unavailableSlotFound.codFechamento);
    }
  };

  const onEventSlotClick = (event: EventClickArg): void => {
    const selectedEvent = event.event;
    const eventFound = calendarEventsFiltered.find(
      (e: { id: string }) => e.id === selectedEvent.id,
    );

    if (
      !eventFound ||
      eventFound.type === "none" ||
      eventFound.appointmentPurposeId === AppointmentPurpose.HorariosEstendidos
    ) {
      return;
    }
    if (eventFound.type === "weekSlot") {
      void goToNewAppointmentPage(currentDate);
      return;
    }
    if (eventFound.type === "closed") {
      handleClosedEvent(eventFound);
      return;
    }

    setCurrentEventSelected(eventFound);

    const appointmentId = parseInt(eventFound.id.split("-")[1]);

    handleOpenAppointmentDetailsDrawer(appointmentId);
  };

  const todayEventsSorted = buildAppointmentsEvents()
    .filter((event) => {
      const eventDate = new Date(event.start);
      return eventDate.getDate() === currentDateObject.getDate();
    })
    .sort((a, b) => {
      const startTimeA = new Date(a.start).getTime();
      const startTimeB = new Date(b.start).getTime();
      return startTimeA - startTimeB;
    });

  const startHourOfTheFirstEventOfDay =
    todayEventsSorted.length > 0 ? new Date(todayEventsSorted[0].start).getHours() : null;

  const getScheduleMinTime = (): string => {
    if (
      !startHourOfTheFirstEventOfDay ||
      startHourOfTheFirstEventOfDay >= DEFAULT_CALENDAR_START_HOUR
    )
      return "07:00:00";

    const paddedHour = startHourOfTheFirstEventOfDay.toString().padStart(2, "0");

    return `${paddedHour}:00:00`;
  };

  const endHourOfTheLastEventOfDay =
    todayEventsSorted.length > 0
      ? new Date(todayEventsSorted[todayEventsSorted.length - 1].end).getHours()
      : null;

  const getScheduleMaxTime = (): string => {
    if (
      !endHourOfTheLastEventOfDay ||
      endHourOfTheLastEventOfDay < DEFAULT_CALENDAR_END_HOUR
    )
      return "23:00:00";

    return "24:00:00";
  };

  const getSlotDurationByEventsDuration = (): string => {
    const appointmentEvents = events.filter((event) => {
      if (!isAppointmentEvent(event.type)) return false;
      if (
        currentCalendarView === "dayGridDay" ||
        currentCalendarView === "dayGridMonth"
      ) {
        return isSameDay(new Date(event.start), new Date(currentDate));
      }
      return true;
    });

    const eventsDuration = appointmentEvents.map((event) => {
      const start = new Date(event.start);
      const end = new Date(event.end);
      return differenceInMinutes(end, start);
    });

    const minDuration = Math.min(...eventsDuration);

    return minDuration <= 15 ? "00:15:00" : "00:30:00";
  };

  const isAppointmentEvent = (type: CalendarEventType): boolean => {
    return ["pending", "confirmed", "cancelled", "old"].includes(type);
  };

  useEffect(() => {
    if (
      !hasScrolledToNowIndicator &&
      allCalendarEvents.length &&
      isEqual(currentDateObject, getCurrentDate())
    ) {
      const nowIndicatorElement = document.querySelector(
        ".fc-timegrid-now-indicator-line",
      );
      if (nowIndicatorElement) {
        nowIndicatorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        setHasScrolledToNowIndicator(true);
      }
    }
  }, [allCalendarEvents.length, currentDateObject, hasScrolledToNowIndicator]);

  return (
    <div className={`view-${currentCalendarView} day-events mt-2`} id="day-events-id">
      <CalendarDayEvent
        events={events}
        currentDate={currentDate}
        slotDuration={getSlotDurationByEventsDuration()}
        minTime={getScheduleMinTime()}
        maxTime={getScheduleMaxTime()}
        weekView={currentCalendarView === "dayGridWeek"}
        onEventClick={onEventSlotClick}
        onEmptyLongClick={(date, start, end) =>
          void goToUnavailableSlotCreatePage(date, start, end)
        }
        onEmptyClick={(date) => void goToNewAppointmentPage(date)}
      />
    </div>
  );
};
