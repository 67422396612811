import { getPlanDisplayNameForList, getPlanBenefits, type Plan } from "@/lib/plans";
import {
  PlanMigrationSelectorRoot,
  PlanMigrationSelectorList,
  PlanMigrationSelectorItem,
} from "@/components/plan-migration-selector";

interface PlanSelectionSectionProps {
  sortedPlans: Plan[];
  checkedPlan: number;
  disabled: (plan: Plan) => boolean;
  memberIsPsico: boolean;
  memberRegistrationDate?: string;
  onPlanSelect: (plan: Plan) => void;
}

export const ChangePlanSelectionSection = ({
  sortedPlans,
  checkedPlan,
  disabled,
  memberIsPsico,
  memberRegistrationDate,
  onPlanSelect,
}: PlanSelectionSectionProps): JSX.Element => {
  return (
    <div>
      <p className="font-sm font-medium text-neutral-600 mb-3">Escolha seu plano</p>
      <PlanMigrationSelectorRoot name="plan-migration">
        <PlanMigrationSelectorList>
          {sortedPlans.map((plan) => (
            <PlanMigrationSelectorItem
              key={`${plan.nome}-${plan.valor}`}
              isChecked={checkedPlan === plan.codPlano}
              value={plan.codPlano}
              onChange={() => onPlanSelect(plan)}
              data={plan}
              displayName={getPlanDisplayNameForList(plan.nome)}
              disabled={disabled(plan)}
              benefits={getPlanBenefits(
                plan.temFidelidade,
                memberIsPsico,
                plan.nome.includes("Light"),
                memberRegistrationDate ?? "",
              )}
            />
          ))}
        </PlanMigrationSelectorList>
      </PlanMigrationSelectorRoot>
    </div>
  );
};
