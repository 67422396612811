import { Download } from "@repo/icons";
import { CpsToast } from "corpus";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import { useState } from "react";
import { Button } from "@/components/button";

interface DownloadFileButtonProps {
  title: string;
  fileContent: string;
  fileName: string;
  fileExtension: "csv";
  onClick?: () => void;
}

export const DownloadFileButton = ({
  title,
  fileName,
  fileContent,
  fileExtension,
  onClick,
}: DownloadFileButtonProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessToast, setOpenSuccessToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const writeAndOpenFile = async (): Promise<void> => {
    onClick && onClick();
    setIsLoading(true);
    try {
      const { uri } = await Filesystem.writeFile({
        path: `${fileName}.${fileExtension}`,
        data: fileContent,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      await FileOpener.openFile({
        path: uri,
      });
      setOpenSuccessToast(true);
    } catch (err) {
      setOpenErrorToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        Icon={Download}
        onClick={() => void writeAndOpenFile()}
        rounded
        fullWidth
        loading={isLoading}
        size="small"
      >
        {title}
      </Button>

      <CpsToast
        title="Arquivo baixado com sucesso. Você pode encontrá-lo no diretório de documentos do seu dispositivo."
        show={openSuccessToast}
        onClose={() => setOpenSuccessToast(false)}
        type="success"
        duration={10}
      />

      <CpsToast
        title="Ocorreu um erro ao baixar o arquivo. Espere alguns minutos e tente novamente. Caso o erro persista, entre em contato com nosso suporte."
        show={openErrorToast}
        onClose={() => setOpenErrorToast(false)}
        type="danger"
        duration={10}
      />
    </>
  );
};
