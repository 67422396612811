import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  PageTitleRoot,
  PageTitleMainContent,
  PageTitleDescription,
} from "@/components/page-title/page-title-root";

export const UserTrackableLinkReportDetailSummaryFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinkReportDetailSummaryFragment on usuariosLinksRastreamentos {
    nome
    descricao
  }
`);

export const UserTrackableLinkReportDetailSummary = (props: {
  userTrackableLink: FragmentType<typeof UserTrackableLinkReportDetailSummaryFragment>;
}): JSX.Element => {
  const userTrackableLink = useFragment(
    UserTrackableLinkReportDetailSummaryFragment,
    props.userTrackableLink,
  );

  const { nome, descricao } = userTrackableLink;

  return (
    <PageTitleRoot>
      <PageTitleMainContent>{nome}</PageTitleMainContent>
      {descricao ? <PageTitleDescription>{descricao}</PageTitleDescription> : null}
    </PageTitleRoot>
  );
};
