import { useNavigate, useRouteContext } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsTextArea } from "corpus";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql.ts";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";

export const AppointmentInfosFormFragment = graphql(/* GraphQL */ `
  fragment AppointmentInfosFormFragment on query_root {
    personalizeAppointment: usuariosProfissionaisOrientacoesSecretarias {
      appointmentInfos: informacoesSobreAgendamento
    }
  }
`);

const upsertAppointmentInfosMutation = graphql(/* GraphQL */ `
  mutation UpsertSecretaryDetailsAppointmentInfosMutation(
    $input: usuariosProfissionaisOrientacoesSecretarias_insert_input!
  ) {
    insert_usuariosProfissionaisOrientacoesSecretarias_one(
      if_matched: {
        match_columns: codUsuario
        update_columns: informacoesSobreAgendamento
      }
      object: $input
    ) {
      informacoesSobreAgendamento
    }
  }
`);

const appointmentInfosSchema = z.object({
  appointmentInfos: z
    .string()
    .max(1000, {
      message: "As orientações de agendamento não podem ter mais de 1000 caracteres",
    })
    .optional(),
});

export const AppointmentInfosForm = ({
  data,
}: {
  data: FragmentType<typeof AppointmentInfosFormFragment>;
}): React.ReactNode => {
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    upsertAppointmentInfosMutation,
  );

  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const fragmentData = useFragment(AppointmentInfosFormFragment, data);

  const form = useForm<z.infer<typeof appointmentInfosSchema>>({
    resolver: zodResolver(appointmentInfosSchema),
    values: {
      appointmentInfos: fragmentData.personalizeAppointment[0]?.appointmentInfos ?? "",
    },
  });

  const onSubmit = async (
    values: z.infer<typeof appointmentInfosSchema>,
  ): Promise<void> => {
    await mutateAsync({
      input: {
        codUsuario: user.codUsuario,
        informacoesSobreAgendamento: values.appointmentInfos,
      },
    });

    await navigate({
      to: "../",
    });
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit} className="gap-0">
        <PageTitleRoot>
          <PageTitleMainContent>Orientações para agendamento</PageTitleMainContent>
          <PageTitleDescription>
            Descreva aqui como seus agendamentos deveriam ser realizados, se há um dia de
            preferência ou unidade preferencial.
          </PageTitleDescription>
        </PageTitleRoot>

        <FormField
          control={form.control}
          name="appointmentInfos"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsTextArea rows={15} {...field} />
              </FormControl>
              <p className="text-neutral-500">{`${form.watch(field.name)?.length ?? 0} / 1000`}</p>
            </FormItem>
          )}
        />

        <FormSubmitButton type="submit">Salvar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
