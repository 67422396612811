import { CpsBadge } from "corpus";

export const PageTitleRoot = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <div className="flex flex-col gap-2 my-4">{children}</div>;
};

export const PageTitleMainContent = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  return <h2 className="text-neutral-600 text-xl font-medium">{children}</h2>;
};

export const PageTitleDescription = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <h3 className="text-neutral-500 text-md font-normal">{children}</h3>;
};

export const PageTitleNewLabel = (): React.ReactNode => {
  return (
    <CpsBadge
      size="default"
      color="success"
      className="text-neutral-600 text-lg font-medium"
    >
      Novidade
    </CpsBadge>
  );
};
