import { CustomerContactVariationBadge } from "@/components/customer-contact-overview/customer-contact-variation-badge";

interface ProfessionalCustomerContactsTotalNumberProps {
  total: number;
  isPercent?: boolean;
  variation?: number;
}

export const CustomerContactsTotalNumber = ({
  total,
  isPercent = false,
  variation = 0,
}: ProfessionalCustomerContactsTotalNumberProps): JSX.Element => {
  const totalText = isPercent ? `${total}%` : total.toString();

  return (
    <div className="text-center flex flex-col mt-3">
      <div className="flex flex-row justify-center items-center gap-1">
        <span className="font-semibold text-2xl">{totalText}</span>{" "}
        {variation ? (
          <CustomerContactVariationBadge>{variation}</CustomerContactVariationBadge>
        ) : null}
      </div>
      <p className="text-sm text-neutral-500">Total</p>
    </div>
  );
};
