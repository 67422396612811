import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type CustomerContactsDistributionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactsTotalNumber } from "@/components/customer-contact-overview/customer-contacts-total-number";
import { trackEvent } from "@/lib/tracking";

export const CustomerContactsDistributionQueryFragment = graphql(/* GraphQL */ `
  fragment CustomerContactsDistributionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
    totalCustomerContacts
    totalOldCustomerContacts
    percentOldCustomerContacts
    totalNewCustomerContacts
    percentNewCustomerContacts
    period
  }
`);

interface ProfessionalCustomerContactsProps {
  data: FragmentType<typeof CustomerContactsDistributionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactsDistribution = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: ProfessionalCustomerContactsProps): JSX.Element => {
  const fragmentData = useFragment(CustomerContactsDistributionQueryFragment, data);

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactsDistributionDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Distribuição de contatos",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactsDistributionDrawer) {
      router.history.back();
    }
  };

  return (
    <div className="my-8 flex flex-col gap-3">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        Distribuição de contatos
      </CustomerContactSectionHeader>
      {fragmentData.length === 0 || !currentMonthData ? (
        <CustomerContactsOverviewEmptyState />
      ) : (
        <>
          <CustomerContactsTotalNumber total={currentMonthData.totalCustomerContacts} />
          <CustomerContactsDistributionChart
            chartData={
              fragmentData as CustomerContactsDistributionQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showCustomerContactsDistributionDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Distribuição de contatos"
      >
        <p>
          Distribuição dos contatos recebidos nos canais de atendimento, classificados por
          origem:
        </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Pacientes: </span>
            <span>contatos de pessoas que já possuem consultas agendadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Novos contatos: </span>
            <span>contatos de pessoas que ainda não realizaram nenhum agendamento.</span>
          </li>
        </ul>
      </CustomerContactDrawer>
    </div>
  );
};

interface CustomerContactsDistributionChartProps {
  chartData: CustomerContactsDistributionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactsDistributionChart = ({
  chartData,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactsDistributionChartProps): JSX.Element => {
  const data = chartData.map((x) => {
    return {
      Pacientes: x.totalOldCustomerContacts,
      "Novos contatos": x.totalNewCustomerContacts,
      mes: x.period,
    };
  });

  const labels = chartData.map((y) => {
    return {
      Pacientes:
        y.percentOldCustomerContacts === 0 ? "" : `${y.percentOldCustomerContacts}%`,
      "Novos contatos":
        y.percentNewCustomerContacts === 0 ? "" : `${y.percentNewCustomerContacts}%`,
    };
  });

  return (
    <div data-testid="customer-contact-distribution-chart">
      <ChartRoot
        selectedKey={String(selectedMonth)}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Novos contatos" fill="#FFD094" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="Pacientes" fill="#F5AE45" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
