import { ExclamationCircle } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { logError } from "@repo/lib";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { WaitingRoomPageQueryDocument } from "@repo/graphql-types/graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import {
  MessageDrawerActions,
  MessageDrawerActionButton,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useGraphQLMutation, useInvalidateQuery } from "@/hooks/use-graphql";
import { type WaitingRoomSearch } from "@/components/waiting-room-appointment-list";
import { trackEvent } from "@/lib/tracking.ts";

export const UntreatedAppointmentMutation = graphql(/* GraphQL */ `
  mutation removeTagAgendamentoAtendido($codAgendamento: Int!) {
    agendamentoNaoAtendido(input: { codAgendamento: $codAgendamento }) {
      sucesso
      errors {
        ... on ValidationError {
          message
        }
      }
    }
  }
`);

interface UntreatedAppointmentDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}

export const UntreatedAppointmentDrawer = ({
  open,
  setOpen,
  onClose,
}: UntreatedAppointmentDrawerProps): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });
  const searchParams: WaitingRoomSearch = useSearch({ strict: false });
  const router = useRouter();
  const { mutateAsync, isPending } = useGraphQLMutation(UntreatedAppointmentMutation);

  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);

  const confirmAction = async (): Promise<void> => {
    const onSuccess = (): void => {
      trackEvent("Status Atendimento Alterado", {
        tipoMarcacao: "não atendido",
        codAgendamento: searchParams.appointmentId,
        codUsuario: user.codUsuario,
        telaOrigem: "Sala de espera",
      });

      invalidateWaitingRoomQuery();
      setOpen(false);
    };

    const onError = (error: Error): void => {
      logError(error, { searchParams });

      setOpenOnErrorDrawer(true);
    };

    await mutateAsync(
      { codAgendamento: searchParams.appointmentId ?? 0 },
      {
        onSuccess,
        onError,
      },
    );
  };

  const setOpenOnErrorDrawer = (value: boolean): void => {
    if (value && searchParams.action === "untreated-appointment-drawer") {
      void router.navigate({
        search: {
          ...searchParams,
          action: "untreated-appointment-error",
          appointmentId: searchParams.appointmentId,
        },
        replace: true,
      });
    } else if (!value && searchParams.action === "untreated-appointment-error") {
      void router.navigate({
        search: { ...searchParams, action: undefined, appointmentId: undefined },
        replace: true,
      });
      router.history.back();
    }
  };

  const closeDrawer = (): void => {
    setOpen(false);
  };

  return (
    <>
      <MessageDrawerRoot
        open={open}
        setOpen={setOpen}
        variant="secondary"
        icon={ExclamationCircle}
        onClose={onClose}
      >
        <MessageDrawerTitle>
          Deseja marcar este paciente como não atendido?
        </MessageDrawerTitle>
        <MessageDrawerBody>
          <p>
            Lembre-se que esta ação não impacta a abertura e alocação de salas na unidade
          </p>
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            loading={isPending}
            onClick={() => void confirmAction()}
          >
            Confirmar
          </MessageDrawerActionButton>
          <MessageDrawerActionButton secondary onClick={closeDrawer}>
            Fechar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <UnexpectedErrorDrawer
        open={searchParams.action === "untreated-appointment-error"}
        setOpen={setOpenOnErrorDrawer}
      />
    </>
  );
};
