import { Right, type SVGIconProps } from "@repo/icons";
import { type FunctionComponent } from "react";
import { type LinkProps } from "@tanstack/react-router";
import { cn } from "@repo/lib";
import { CpsBadge } from "corpus";
import {
  ListRoot,
  Item,
  ItemMainContent,
  ItemContentStart,
  ItemContentEnd,
} from "@/components/list";
import { trackEvent } from "@/lib/tracking.ts";

export interface MenuListItem {
  name: string;
  Icon: FunctionComponent<SVGIconProps>;
  route?: LinkProps["to"];
  params?: LinkProps["params"];
  search?: LinkProps["search"];
  externalRoute?: string;
  showTag?: boolean;
  showSoonTag?: boolean;
  target?: "_blank" | "_self";
  description?: string;
  variant?: "neutral" | "danger";
  onClick?: () => void;
}

export interface MenuListProps {
  menuItems: MenuListItem[];
  title?: string;
}

export const MenuList = ({ menuItems, title }: MenuListProps): JSX.Element => {
  const handleClickEvent = (label: string): void => {
    trackEvent("Item de Menu Clicado", {
      label,
      page: "/settings",
    });
  };

  const handleClickMenuItem = (name: string, onClick?: () => void): void => {
    handleClickEvent(String(name));
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {title ? (
        <h3 className="text-md font-normal text-neutral-700 pt-3">{title}</h3>
      ) : null}
      <ListRoot className={cn(title && "pl-6")}>
        {menuItems.map(
          ({
            Icon,
            name,
            route,
            params,
            search,
            externalRoute,
            showTag = false,
            showSoonTag = false,
            target = "_self",
            variant = "neutral",
            onClick,
            description,
          }) => (
            <Item
              key={name}
              linkProps={{ to: route, params, search, target, preload: false }}
              externalRoute={externalRoute}
              onClick={() => handleClickMenuItem(name, onClick)}
            >
              <ItemContentStart className="self-start">
                <Icon
                  className={cn({
                    "fill-neutral-300 text-center shrink-0": true,
                    "fill-red-300": variant === "danger",
                  })}
                  size={24}
                />
              </ItemContentStart>
              <ItemMainContent
                className={cn("text-neutral-700 text-md", {
                  "flex flex-row justify-between w-full": showTag || showSoonTag,
                })}
              >
                <div>
                  <p
                    className={cn("flex flex-row gap-2", {
                      "text-neutral-600": true,
                      "text-red-500": variant === "danger",
                    })}
                  >
                    {name}
                    {showTag ? <CpsBadge color="helper">Novo</CpsBadge> : null}
                  </p>

                  <p className="text-sm text-gray-600 font-normal pr-2">{description}</p>
                </div>
              </ItemMainContent>
              <ItemContentEnd className="flex flex-row">
                {showSoonTag ? (
                  <CpsBadge color="danger" className="self-start">
                    Em breve
                  </CpsBadge>
                ) : <Right className="shrink-0" size={24} />}
              </ItemContentEnd>
            </Item>
          ),
        )}
      </ListRoot>
    </>
  );
};
