import { useRouter, useSearch } from "@tanstack/react-router";
import { SingleNumberChart } from "@/components/charts/single-number-chart";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { trackEvent } from "@/lib/tracking";

interface RescuedNoShowAppointmentsProps {
  data?: {
    month: string;
    rescuedAppointmentType: string;
    appointmentsRescuedSum?: number;
    sentMessagesSum?: number;
  };
}

export const RescuedNoShowAppointments = ({
  data,
}: RescuedNoShowAppointmentsProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showRescuedNoShowAppointmentsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Faltas recuperadas",
      });

      void router.navigate({ search });
    } else if (searchParams.showRescuedNoShowAppointmentsDrawer) {
      router.history.back();
    }
  };

  const drawerText =
    "Para pacientes que faltaram e não remarcaram, enviamos uma mensagem convidando-os a realizar um novo agendamento. Este gráfico mostra quantos realizaram o agendamento após receberem nossa mensagem.";

  const displayText =
    data?.appointmentsRescuedSum && data.appointmentsRescuedSum > 1
      ? "Faltas recuperadas"
      : "Falta recuperada";

  const legendText =
    data?.sentMessagesSum && data.sentMessagesSum > 1
      ? "Mensagens enviadas"
      : "Mensagem enviada";

  return (
    <>
      {data ? (
        <SingleNumberChart
          chartTitle="Faltas recuperadas"
          totalNumber={data.appointmentsRescuedSum ?? 0}
          displayText={displayText}
          legendNumber={(data.sentMessagesSum ?? 0).toString()}
          legendText={legendText}
          legendIndex={1}
          onOpenChange={onOpenChange}
        />
      ) : (
        <>
          <CustomerContactSectionHeader onOpenChange={onOpenChange}>
            Faltas recuperadas
          </CustomerContactSectionHeader>
          <CustomerContactsOverviewEmptyState />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showRescuedNoShowAppointmentsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Faltas recuperadas"
      >
        {drawerText}
      </CustomerContactDrawer>
    </>
  );
};
