import { z } from "zod";

export const userTrackableLinksCreate = z.object({
  name: z
    .string({ required_error: "Você precisa informar o nome da campanha" })
    .min(1, { message: "Você precisa informar o nome da campanha" })
    .max(50, { message: "O nome da campanha não pode ter mais de 50 caracteres" }),
  description: z
    .string()
    .max(300, { message: "A descrição da campanha não pode ter mais de 300 caracteres" })
    .optional(),
});
