import { createFileRoute } from "@tanstack/react-router";
import { graphql, type FragmentType } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { z } from "zod";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import {
  WaitingRoomAppointmentMenu,
  type WaitingRoomAppointmentMenuFragment,
} from "@/components/waiting-room-menu";

const WaitingRoomAppointmentMenuQuery = graphql(/* GraphQL */ `
  query WaitingRoomAppointmentMenuQuery($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      ...WaitingRoomAppointmentMenuFragment
    }
  }
`);

export const AppointmentMenuPage = (): JSX.Element => {
  const { codAgendamento } = Route.useParams();

  const queryResult = useGraphQL(WaitingRoomAppointmentMenuQuery, {
    codAgendamento: Number(codAgendamento),
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Agendamento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <WaitingRoomAppointmentMenu
              data={
                data.agendamento as FragmentType<
                  typeof WaitingRoomAppointmentMenuFragment
                >
              }
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

const waitingRoomMenuSearchSchema = z.object({
  action: z.string().optional(),
});

export const Route = createFileRoute("/waiting-room/$codAgendamento/menu/")({
  component: AppointmentMenuPage,
  validateSearch: waitingRoomMenuSearchSchema,
});
