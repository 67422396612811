import { type FragmentType, useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { CircleCheck } from "@repo/icons";
import { logError } from "@repo/lib";
import { useRouter, useSearch } from "@tanstack/react-router";
import { WaitingRoomPageQueryDocument } from "@repo/graphql-types/graphql";
import { Button } from "@/components/button";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";
import { type Info, InfoItem, ListRoot } from "@/components/list";
import { calculateFormattedDuration } from "@/lib/string";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql.ts";

export const ReceiveRoomNowFragment = graphql(`
  fragment ReceiveRoomNowFragment on agendamentos {
    codAgendamento
    nome
    unidade: Unidade {
      nomeLimpo
    }
    horaInicio
    horaFim
  }
`);

const ReceiveRoomNowMutation = graphql(`
  mutation ReceiveRoomNow($input: ReceberSalaAgoraInput) {
    receberSalaAgora(input: $input) {
      data: agendamentoApiOutput {
        codAgendamento
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
        ... on RepeatedPatientError {
          __typename
          message
        }
      }
    }
  }
`);

interface ReceiveRoomNowProps {
  data: FragmentType<typeof ReceiveRoomNowFragment>;
}

export const ReceiveRoomNowForm = ({ data }: ReceiveRoomNowProps): JSX.Element => {
  const appointment = useFragment(ReceiveRoomNowFragment, data);
  const params = useSearch({
    from: "/waiting-room/$codAgendamento/receive-room-now/",
  });
  const router = useRouter();

  const { mutateAsync, isPending } =
    useGraphQLMutationWithErrorHandler(ReceiveRoomNowMutation);

  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);

  const setOpenFeedbackDrawer = (value: boolean, action: string): void => {
    if (value && params.action === action) {
      void router.navigate({
        search: {
          action,
        },
      });
    } else if (!value && params.action === action) {
      if (params.action === "receive-room-now-success") {
        handleCloseDrawerSuccessAction();
      }
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const { codAgendamento } = appointment;

    const onError = (error: Error): void => {
      logError(error);
    };

    const onSuccess = (): void => {
      void router.navigate({
        search: {
          action: "receive-room-now-success",
        },
      });
    };

    await mutateAsync(
      { input: { codAgendamento } },
      {
        onSuccess,
        onError,
      },
    );
  };

  const handleCloseDrawerSuccessAction = (): void => {
    invalidateWaitingRoomQuery();

    void router.navigate({ to: "/waiting-room" });
  };

  const infos: Info[] = [
    {
      label: "Duração",
      value: calculateFormattedDuration(appointment.horaInicio, appointment.horaFim),
    },
    {
      label: "Paciente",
      value: appointment.nome ?? "-",
    },
    {
      label: "Unidade",
      value: appointment.unidade.nomeLimpo ?? "-",
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-2">
        <h4 className="font-semibold text-md text-neutral-600">
          Deseja receber a sala agora?
        </h4>
        <ListRoot>
          {infos.map((info) => (
            <InfoItem key={`${info.label}-${info.value}`} itemInfo={info} />
          ))}
        </ListRoot>
        <Button onClick={() => void handleSubmit()} loading={isPending}>
          Confirmar
        </Button>
      </div>
      <MessageDrawerRoot
        icon={CircleCheck}
        open={params.action === "receive-room-now-success"}
        setOpen={(value) => setOpenFeedbackDrawer(value, "receive-room-now-success")}
        variant="secondary"
      >
        <MessageDrawerTitle>Agendamento remarcado</MessageDrawerTitle>
        <MessageDrawerBody>
          Estamos preparando uma sala para você, será liberada em instantes
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton onClick={handleCloseDrawerSuccessAction}>
            Ir para sala de espera
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
    </>
  );
};
