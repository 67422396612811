import { atomWithReset } from "jotai/utils";
import { type CreatePaymentFormValues } from "@/lib/form-schemas/create-payment-schema";

export const createPaymentFormAtom = atomWithReset<CreatePaymentFormValues>({
  installments: 1,
  patientName: "",
  phone: "",
  value: "",
  email: "",
  sendPaymentAutomatically: true,
  serviceName: "",
  paymentMethods: [],
});

export const isPaymentInfoStepConcluded = (form: CreatePaymentFormValues): boolean => {
  const { paymentMethods, serviceName, email, value, phone, patientName } = form;

  return Boolean(
    paymentMethods.length > 0 && serviceName && email && value && phone && patientName,
  );
};
