import { Share } from "@repo/icons";
import { CpsButtonText, CpsToast } from "corpus";
import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Link, useParams } from "@tanstack/react-router";
import { Share as CapacitorShare } from "@capacitor/share";
import { useState } from "react";
import { Button } from "@/components/button";

export const UserTrackableLinksDetailsActionsFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinksDetailsActionsFragment on usuariosLinksRastreamentos {
    nome
    descricao
    link
  }
`);

interface UserTrackableLinksDetailsActionsProps {
  data: FragmentType<typeof UserTrackableLinksDetailsActionsFragment>;
}

export const UserTrackableLinksDetailsActions = ({
  data,
}: UserTrackableLinksDetailsActionsProps): JSX.Element => {
  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const { userTrackableLinkId } = useParams({
    from: "/settings/livance-via/user-trackable-links/$userTrackableLinkId/",
  });

  const fragmentData = useFragment(UserTrackableLinksDetailsActionsFragment, data);
  const { link, nome, descricao } = fragmentData;

  const onShareButtonClick = async (): Promise<void> => {
    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(link);
      setOpenCopiedLinkToast(true);
    }

    await CapacitorShare.share({
      title: nome,
      text: descricao ?? "",
      url: link,
    });
  };

  return (
    <>
      <div className="flex flex-col gap-4 items-center">
        <Button
          Icon={Share}
          fullWidth
          onClick={() => void onShareButtonClick()}
          data-testid="share-trackable-link-button"
        >
          Compartilhar Link
        </Button>
        <Link
          to="/settings/livance-via/user-trackable-links/$userTrackableLinkId/report/"
          params={{
            userTrackableLinkId,
          }}
        >
          <CpsButtonText className="text-neutral-500">
            Ver relatório da campanha
          </CpsButtonText>
        </Link>
      </div>

      <CpsToast
        title="Link copiado"
        description="Seu link de campanha foi copiado com sucesso"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={10}
      />
    </>
  );
};
