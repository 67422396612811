import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { useSuspenseGraphQL } from "@/hooks/use-graphql";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header";
import { Page } from "@/components/page";
import { PendingComponent } from "@/components/pending-component.tsx";
import { PersonalizeAppointmentForm } from "@/components/secretary-details/personalize-appointment-form";

export const GetSecretaryDetailsPageQuery = graphql(/* GraphQL */ `
  query GetSecretaryDetailsPageQuery {
    ...PersonalizeAppointmentFormFragment
  }
`);

export const SecretaryDetailsPage = (): JSX.Element => {
  const { data } = useSuspenseGraphQL(GetSecretaryDetailsPageQuery, {});

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="" align="center" />
      </HeaderRoot>
      <Page>
        <PersonalizeAppointmentForm data={data} />
      </Page>
    </>
  );
};
export const Route = createFileRoute("/settings/livance-via/secretary-details/")({
  component: SecretaryDetailsPage,
  pendingComponent: () => <PendingComponent title="" />,
});
