import { type ReactNode } from "react";

interface CustomerContactsSectionHeaderProps {
  children: ReactNode;
  onOpenChange: (open: boolean) => void;
}

export const CustomerContactSectionHeader = ({
  children,
  onOpenChange,
}: CustomerContactsSectionHeaderProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="font-medium text-neutral-600 text-md">{children}</h2>
      <button
        className="font-medium text-sm text-[#0080A5] self-start"
        onClick={() => {
          onOpenChange(true);
        }}
        type="button"
      >
        O que é isso?
      </button>
    </div>
  );
};
