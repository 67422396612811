import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { createFileRoute } from "@tanstack/react-router";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { UserTrackableLinksDetailsActions } from "@/components/user-trackable-links/details/user-trackable-links-details-actions";
import { UserTrackableLinksDetailsDescription } from "@/components/user-trackable-links/details/user-trackable-links-details-description";
import { UserTrackableLinksDetailsLinkInput } from "@/components/user-trackable-links/details/user-trackable-links-details-link-input";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const UserTrackableLinksDetailsPageQuery = graphql(`
  query UserTrackableLinksDetailsPageQuery($userTrackableLinkId: Int!) {
    userTrackableLink: usuariosLinksRastreamentos_by_pk(
      codUsuarioLinkRastreamento: $userTrackableLinkId
    ) {
      ...UserTrackableLinksDetailsDescriptionFragment
      ...UserTrackableLinksDetailsLinkInputFragment
      ...UserTrackableLinksDetailsActionsFragment
    }
  }
`);

export const UserTrackableLinksDetailsPage = (): JSX.Element => {
  const { userTrackableLinkId } = Route.useParams();
  const queryResult = useGraphQL(UserTrackableLinksDetailsPageQuery, {
    userTrackableLinkId: parseInt(userTrackableLinkId),
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Minha Campanha" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data?.userTrackableLink ? (
            <div className="flex flex-col gap-6">
              <UserTrackableLinksDetailsDescription data={data.userTrackableLink} />
              <UserTrackableLinksDetailsLinkInput data={data.userTrackableLink} />
              <UserTrackableLinksDetailsActions data={data.userTrackableLink} />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/livance-via/user-trackable-links/$userTrackableLinkId/",
)({
  component: UserTrackableLinksDetailsPage,
});
