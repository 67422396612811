import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { Left, Stories } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType } from "@repo/graphql-types";
import { format } from "date-fns-tz";
import { endOfDay } from "date-fns";
import {
  HeaderRoot,
  HeaderUpButton,
  HeaderButton,
  HeaderTitle,
} from "@/components/header";
import { Page } from "@/components/page";
import { CustomerContactsDistribution } from "@/components/customer-contact-overview/customer-contacts-distribution";
import { CustomerContactReason } from "@/components/customer-contact-overview/customer-contact-reason";
import { SchedulingConversionRate } from "@/components/customer-contact-overview/scheduling-conversion-rate";
import { ReasonsForNotScheduling } from "@/components/customer-contact-overview/reasons-for-not-scheduling";
import { HumanCustomerContacts } from "@/components/customer-contact-overview/human-customer-contacts";
import { TimePeriodSelector } from "@/components/customer-contact-overview/customer-contact-time-period-selector";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { useGraphQL } from "@/hooks/use-graphql";
import { TotalCustomerContacts } from "@/components/customer-contact-overview/total-customer-contacts";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { RescuedAppointments } from "@/components/customer-contact-overview/rescued-appointments-section";
import { getFormattedMonthsArray } from "@/lib/date";
import { trackEvent } from "@/lib/tracking";

const CustomerContactOverviewPageQuery = graphql(/* GraphQL */ `
  query CustomerContactOverviewPageQuery($from: String!, $to: String!) {
    NuntiusGetCustomerContactManyPeriodSummary(arg1: { from: $from, to: $to }) {
      ...TotalCustomerContactsQueryFragment
      ...CustomerContactsDistributionQueryFragment
      ...SchedulingConversionRateSessionFragmentQueryFragment
      ...ReasonsForNotSchedulingSessionFragmentQueryFragment
      ...HumanCustomerContactsSessionFragmentQueryFragment
      ...CustomerContactReasonFragment
    }

    rescuedAppointments: analyticsMembroTotalDeAgendamentosResgatadosPorFluxo(
      arg1: { dataInicio: $from, dataFim: $to }
    ) {
      ...RescuedAppointmentsFragment
    }
  }
`);

export const CustomerContactOverviewPage = (): JSX.Element => {
  const fromDate = format(new Date(2024, 10), "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const toDate = format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const monthsList = getFormattedMonthsArray(fromDate, toDate);

  const FIVE_MINUTES_STALE_TIME = 300000;
  const queryResult = useGraphQL(
    CustomerContactOverviewPageQuery,
    {
      from: fromDate,
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
    },
  );

  const { data } = queryResult;

  const periodData: FragmentType<typeof CustomerContactOverviewPageQuery>[] =
    (data?.NuntiusGetCustomerContactManyPeriodSummary ?? []) as FragmentType<
      typeof CustomerContactOverviewPageQuery
    >[];

  const router = useRouter();

  const rescuedAppointmentsData: FragmentType<typeof CustomerContactOverviewPageQuery> =
    (data?.rescuedAppointments ?? []) as FragmentType<
      typeof CustomerContactOverviewPageQuery
    >;

  const navigateToStoriesPage = (): void => {
    void router.navigate({
      to: "/settings/livance-via/customer-contact-overview/stories-feed",
    });
  };

  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const selectedMonth: string = searchParams.selectedMonth
    ? String(searchParams.selectedMonth)
    : monthsList[0];

  const onSelectedMonthChange = (newMonth: string): void => {
    trackEvent("Novo Mês Relatório Selecionado", {
      "Mês selecionado": newMonth,
      Origem: "Livance Via",
    });

    const search = {
      ...searchParams,
    };

    search.selectedMonth = newMonth;
    void router.navigate({ search, replace: true });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Relatório de Contatos" align="center" />
        <HeaderButton icon={Stories} align="end" onClick={navigateToStoriesPage} />
      </HeaderRoot>
      <TimePeriodSelector
        monthsList={monthsList}
        onSelectedMonthChange={onSelectedMonthChange}
      />
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <>
              <TotalCustomerContacts
                data={periodData}
                selectedMonth={selectedMonth}
                onSelectedMonthChange={onSelectedMonthChange}
              />
              <CustomerContactsDistribution
                data={periodData}
                selectedMonth={selectedMonth}
                onSelectedMonthChange={onSelectedMonthChange}
              />
              <CustomerContactReason data={periodData} selectedMonth={selectedMonth} />
              <SchedulingConversionRate
                data={periodData}
                selectedMonth={selectedMonth}
                onSelectedMonthChange={onSelectedMonthChange}
              />
              <ReasonsForNotScheduling data={periodData} selectedMonth={selectedMonth} />
              <HumanCustomerContacts
                data={periodData}
                selectedMonth={selectedMonth}
                onSelectedMonthChange={onSelectedMonthChange}
              />
              <RescuedAppointments
                fragmentData={rescuedAppointmentsData}
                selectedMonth={selectedMonth}
              />
            </>
          ) : (
            <CustomerContactsOverviewEmptyState />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/livance-via/customer-contact-overview/")({
  component: CustomerContactOverviewPage,
});
