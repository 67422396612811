import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import {
  createFileRoute,
  useLoaderData,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useState } from "react";
import type { UsuariosProfissionaisPlanos } from "@repo/graphql-types/graphql";
import { useGraphQL, useGraphQLMutation } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  isPlanValidForMigration,
  getFilteredSortedPlans,
  type Plan,
  getCurrentPlan,
} from "@/lib/plans";
import { CurrentPlanCard } from "@/components/current-plan-card";
import { trackEvent } from "@/lib/tracking";
import { Button } from "@/components/button";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { SuccessChangePlanDrawer } from "@/components/success-change-plan-drawer";
import { ChangePlanAlert } from "@/components/change-plan-alert";
import { ChangePlanCheckboxTerms } from "@/components/change-plan-checkbox-terms";
import { ChangePlanSelectionSection } from "@/components/change-plan-selection-section";
import { useMemberInfo } from "@/lib/member";

const ChangePlanPageQuery = graphql(/* GraphQL */ `
  query ChangePlanPageQuery {
    ...CurrentPlanCardFragment
    ...ChangePlanAlertFragment
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        valor
        temFidelidade
      }
      dataInicio
    }
    planos(where: { ativo: { _eq: true } }) {
      codPlano
      nome
      valor
      temFidelidade
    }
    usuariosProfissionais {
      dataValidacao
    }
    usuariosEspecialidades {
      codEspecialidade
    }
  }
`);

const UpdateUsuarioPlanoMigracaoMutation = graphql(`
  mutation LivanceApiUsuarioPlanoMigracao($input: LivanceApiUsuarioPlanoMigracaoInput!) {
    LivanceApiUsuarioPlanoMigracao(arg1: $input) {
      codPlano
    }
  }
`);

export const ChangePlanPage = (): JSX.Element => {
  const { user, queryClient, flags } = useRouteContext({ strict: false });
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/profile/change-plan/",
  });

  const { enableFullPlansForPsico } = useLoaderData({
    from: "/settings/profile/change-plan/",
  });

  const [isChecked, setIsChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(0);

  const queryResult = useGraphQL(ChangePlanPageQuery);
  const { data } = queryResult;

  const {
    planos: plans = [],
    usuarioPlano = [],
    usuariosProfissionais = [],
    usuariosEspecialidades = [],
  } = data ?? {};

  const currentPlan = getCurrentPlan(usuarioPlano as UsuariosProfissionaisPlanos[]);
  const currentPlanRegistrationDate = usuarioPlano[0]?.dataInicio ?? null;
  const { memberRegistrationDate, memberIsPsico } = useMemberInfo(
    usuariosProfissionais,
    usuariosEspecialidades,
  );

  const memberRegistrationDateCheckingFlag = flags[
    "deve-considerar-plano-mensal-como-permanencia-de-3-meses"
  ]
    ? memberRegistrationDate
    : "";

  const { mutateAsync: updateUsuarioPlanoMigracaoAsync, isPending } = useGraphQLMutation(
    UpdateUsuarioPlanoMigracaoMutation,
  );

  const handlePlanChange = (value: number): void => {
    if (checkedPlan === value) {
      setCheckedPlan(0);
      return;
    }

    trackEvent("Opção de Plano Selecionado", {
      codUsuario: user.codUsuario,
      codPlano: value,
      label: "Alterar Plano",
      page: "/settings/profile/change-plan/",
    });
    setCheckedPlan(value);
  };

  const handleDrawerState = (action: string | null, navigateBack = false): void => {
    if (navigateBack) {
      router.history.go(-2);
      return;
    }

    void router.navigate({
      to: "/settings/profile/change-plan/",
      search: { action },
    });
  };

  const handleSuccessfulUpdate = (): void => {
    setCheckedPlan(0);
    setIsChecked(false);
    void queryClient.resetQueries({ queryKey: ["MyProfilePageQuery"] });
    handleDrawerState("change-plan-success");
  };

  const handlePlanUpdate = async (): Promise<void> => {
    await updateUsuarioPlanoMigracaoAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          codPlano: checkedPlan,
        },
      },
      {
        onSuccess: handleSuccessfulUpdate,
        onError: () => handleDrawerState("error"),
      },
    );
  };

  const handleSuccessDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "change-plan-success" },
      });
    } else if (searchParams.action === "change-plan-success") {
      router.history.go(-2);
    }
  };

  const handlePlanSelection = (plan: Plan): void => {
    if (disabled(plan)) return;

    handlePlanChange(plan.codPlano ?? 0);
    document.getElementById("change-plan-button")?.scrollIntoView({ behavior: "smooth" });
  };

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "error" },
      });
    } else if (searchParams.action === "error") {
      router.history.back();
    }
  };

  const disabled = (plan: Plan): boolean => {
    return !isPlanValidForMigration(
      plan,
      currentPlan,
      memberRegistrationDate,
      currentPlanRegistrationDate,
      memberIsPsico,
      enableFullPlansForPsico,
    );
  };

  const sortedPlans = getFilteredSortedPlans(
    plans,
    currentPlan,
    memberRegistrationDate,
    currentPlanRegistrationDate,
    memberIsPsico,
    enableFullPlansForPsico,
  );

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Alterar Plano" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-center items-stretch gap-2 py-4">
                <p className="font-sm font-medium text-neutral-600">Plano atual</p>
                <CurrentPlanCard
                  data={data}
                  memberRegistrationDate={memberRegistrationDate}
                />
                <ChangePlanAlert
                  data={data}
                  disableFullPlansForPsico={!enableFullPlansForPsico}
                />
              </div>

              <ChangePlanSelectionSection
                sortedPlans={sortedPlans}
                checkedPlan={checkedPlan}
                disabled={disabled}
                memberIsPsico={memberIsPsico}
                memberRegistrationDate={memberRegistrationDateCheckingFlag}
                onPlanSelect={handlePlanSelection}
              />

              <ChangePlanCheckboxTerms isChecked={isChecked} onChange={setIsChecked} />

              <Button
                id="change-plan-button"
                fullWidth
                disabled={!isChecked || checkedPlan === 0}
                onClick={() => {
                  void handlePlanUpdate();
                }}
                loading={isPending}
              >
                Alterar Plano
              </Button>

              <UnexpectedErrorDrawer
                open={searchParams.action === "error"}
                setOpen={setShowUnexpectedErrorDrawer}
              />
              <SuccessChangePlanDrawer
                open={searchParams.action === "change-plan-success"}
                setOpen={handleSuccessDrawer}
                onClickProfile={() => {
                  router.history.go(-2);
                }}
                onClickSchedule={() => void router.navigate({ to: "/" })}
              />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/change-plan/")({
  component: ChangePlanPage,
  beforeLoad: ({ context }) => ({
    enableFullPlansForPsico: context.ldClient.getEnableFullPlansForPsico(),
  }),
  loader: ({ context }) => ({
    enableFullPlansForPsico: context.enableFullPlansForPsico,
  }),
});
