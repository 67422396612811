import { getFeedback } from "@sentry/react";
import { motion } from "framer-motion";
import { useRef, useState, useEffect, useCallback } from "react";
import { X } from "@repo/icons";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { trackEvent } from "@/lib/tracking";

interface FeedbackDialog {
  open: () => void;
  close: () => void;
}

export const UserFeedbackButton = (): JSX.Element | null => {
  const constraintsRef = useRef<HTMLDivElement>(null);
  const { flags } = useRouteContext({ strict: false });
  const [closed, setClosed] = useState(false);
  const [formFeedback, setFormFeedback] = useState<FeedbackDialog | undefined>(undefined);

  const feedback = getFeedback();

  const router = useRouter();
  const currentPath = router.state.location.pathname;

  const { routesWithUserFeedback } =
    flags["configura-rotas-que-aparece-barrinha-de-feedback"];

  const shouldShowFeedback = (): boolean => {
    if (routesWithUserFeedback === "all") {
      return true;
    } else if (Array.isArray(routesWithUserFeedback)) {
      return routesWithUserFeedback.some((route) => {
        const regex = new RegExp(
          `^${route.replace(/\*/g, "[^/]+").replace(/\//g, "\\/")}$`,
        );
        return regex.test(currentPath);
      });
    } else if (routesWithUserFeedback === "none") {
      return false;
    }

    return false;
  };

  const showFeedback = shouldShowFeedback();

  const createFormFeedback = useCallback(async (): Promise<void> => {
    const form = await feedback?.createForm();

    if (form) {
      form.appendToDom();
      setFormFeedback(form);
    }
  }, [feedback]);

  useEffect(() => {
    void createFormFeedback();

    const setConstraintsHeight = (): void => {
      if (constraintsRef.current) {
        constraintsRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    setConstraintsHeight();

    window.addEventListener("resize", setConstraintsHeight);

    return () => {
      window.removeEventListener("resize", setConstraintsHeight);
    };
  }, [createFormFeedback]);

  if (!showFeedback) {
    return null;
  }

  if (!feedback || !formFeedback || closed) {
    return null;
  }

  return (
    <div
      className="fixed top-0 right-0 h-[100vh] w-14 z-10 flex pointer-events-none"
      ref={constraintsRef}
    >
      <motion.div
        drag="y"
        dragSnapToOrigin={false}
        dragConstraints={constraintsRef}
        dragMomentum={false}
        style={{
          rotate: -90,
        }}
        className="absolute top-1/4 origin-bottom-right px-4 py-2 min-w-64 m-auto right-0 rounded-t-md bg-primary-400 tracking-wider text-white drop-shadow-md pointer-events-auto flex items-center"
        onClick={() => {
          formFeedback.open();

          trackEvent("Enviar Feedback Aberto");
        }}
        id="feedback-button"
      >
        <button type="button" className="flex-1 whitespace-nowrap">
          Enviar Feedback
        </button>
        <button
          type="button"
          onClick={(event) => {
            setClosed(true);
            trackEvent("Enviar Feedback Fechado");
            event.stopPropagation();
          }}
        >
          <X className="fill-white mx-2" />
        </button>
      </motion.div>
    </div>
  );
};
