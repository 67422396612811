import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type HumanCustomerContactsSessionFragmentQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactsTotalNumber } from "@/components/customer-contact-overview/customer-contacts-total-number";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { trackEvent } from "@/lib/tracking";

export const HumanCustomerContactsSessionFragmentQueryFragment = graphql(/* GraphQL */ `
  fragment HumanCustomerContactsSessionFragmentQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
    totalHumanCustomerContacts
    totalHumanCustomerContactsChat
    percentHumanCustomerContactsChat
    totalHumanCustomerContactsVoice
    percentHumanCustomerContactsVoice
    period
  }
`);

interface HumanCustomerContactsProps {
  data: FragmentType<typeof HumanCustomerContactsSessionFragmentQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const HumanCustomerContacts = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: HumanCustomerContactsProps): JSX.Element => {
  const fragmentData = useFragment(
    HumanCustomerContactsSessionFragmentQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showHumanCustomerContactsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Contatos tratados por secretárias",
      });

      void router.navigate({ search });
    } else if (searchParams.showHumanCustomerContactsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        Contatos tratados por secretárias
      </CustomerContactSectionHeader>
      {fragmentData.length === 0 || currentMonthData === undefined ? (
        <CustomerContactsOverviewEmptyState />
      ) : (
        <>
          <CustomerContactsTotalNumber
            total={currentMonthData.totalHumanCustomerContacts}
          />
          <HumanCustomerContactsChart
            data={
              fragmentData as HumanCustomerContactsSessionFragmentQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showHumanCustomerContactsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Contatos tratados por secretárias"
      >
        <p>
          Número de contatos que tiveram interação com alguma pessoa do time de
          secretárias Livance:
        </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Telefone: </span>
            <span>Ligações recebidas e realizadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">WhatsApp: </span>
            <span>Conversas abertas.</span>
          </li>
        </ul>
      </CustomerContactDrawer>
    </div>
  );
};

interface HumanCustomerContactsContentProps {
  data: HumanCustomerContactsSessionFragmentQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const HumanCustomerContactsChart = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: HumanCustomerContactsContentProps): JSX.Element => {
  const chartData = data.map((x) => {
    return {
      Telefone: x.totalHumanCustomerContactsVoice,
      WhatsApp: x.totalHumanCustomerContactsChat,
      mes: x.period,
    };
  });

  const chartLabels = data.map((x) => {
    return {
      Telefone:
        x.percentHumanCustomerContactsVoice === 0
          ? ""
          : `${x.percentHumanCustomerContactsVoice}%`,
      WhatsApp:
        x.percentHumanCustomerContactsChat === 0
          ? ""
          : `${x.percentHumanCustomerContactsChat}%`,
    };
  });

  return (
    <div data-testid="human-customer-contact-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={chartData}
        labels={chartLabels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Telefone" fill="#72ABBC" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="WhatsApp" fill="#00658A" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
