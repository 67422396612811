import Fuse, { type FuseResult } from "fuse.js";
import { useMemo } from "react";

interface UseFuseProps<T> {
  items: T[];
  keys: string[];
  input: string;
}

export const useFuse = <T>({ items, keys, input }: UseFuseProps<T>): {item: T}[] | FuseResult<T>[] => {
  const fuse = useMemo(() => {
    const fuseOptions = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,
      keys,
    };
    return new Fuse(items, fuseOptions);
  }, [items, keys]);

  const results = useMemo(() => {
    if (input.trim() === "") {
      return items.map((item) => ({ item }));
    }
    return fuse.search(input);
  }, [items, fuse, input]);

  return results;
};