import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsCheckboxV2, CpsInput } from "corpus";
import { User } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { checkIfAppointmentHasOccurred, formatCurrencyString } from "@repo/lib";
import { type CreatePaymentFormFragmentFragment } from "@repo/graphql-types/graphql";
import { useAtom } from "jotai";
import { useNavigate, useRouteContext, useSearch } from "@tanstack/react-router";
import { useCallback, useEffect } from "react";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { InputCurrency } from "@/components/input-currency";
import { SelectInput } from "@/components/select-input";
import { InputPhone } from "@/components/input-phone";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { InputEmail } from "@/components/input-email";
import { createPaymentFormAtom } from "@/lib/atoms/create-payment-atom";
import { OnlinePaymentTypesCheckboxGroup } from "@/components/online-payment-types-checkbox-group";
import {
  CreatePaymentFormSchema,
  type CreatePaymentFormValues,
} from "@/lib/form-schemas/create-payment-schema";
import { trackEvent } from "@/lib/tracking";

export const CreatePaymentFormFragment = graphql(`
  fragment CreatePaymentFormFragment on query_root {
    agendamento: agendamentos_by_pk(codAgendamento: $appointmentId) {
      nome
      telefone
      email
      codAgendamento
      horaInicio
      data
      compromisso: UsuarioCompromisso {
        codUsuarioCompromisso
        valor
        nome
      }
    }
    formasDePagamento: expensum_payment_type {
      ...OnlinePaymentTypesCheckboxGroupFragment
    }
  }
`);

interface CreatePaymentFormProps {
  data: FragmentType<typeof CreatePaymentFormFragment>;
}

export const CreatePaymentForm = ({ data }: CreatePaymentFormProps): JSX.Element => {
  const { flags } = useRouteContext({ strict: false });
  const { origin } = useSearch({ from: "/settings/payments/create/" });
  const showCheckboxSendPaymentAutomatically = flags["envio-automatico-link-pagamento"];

  const [paymentFormAtom, setPaymentFormAtom] = useAtom(createPaymentFormAtom);

  const queryData = useFragment<CreatePaymentFormFragmentFragment>(
    CreatePaymentFormFragment,
    data,
  );

  const navigate = useNavigate();
  const appointment = queryData.agendamento;
  const paymentTypes = queryData.formasDePagamento;

  type OriginOnlinePaymentType = "avulso" | "pre" | "pos";

  const getOnlinePaymentType = useCallback((): OriginOnlinePaymentType => {
    let isAppointmentInThePast: boolean | undefined;

    if (appointment) {
      isAppointmentInThePast = checkIfAppointmentHasOccurred(
        appointment.data,
        appointment.horaInicio,
      );
    }

    if (!appointment?.codAgendamento) return "avulso";

    return isAppointmentInThePast ? "pos" : "pre";
  }, [appointment]);

  useEffect(() => {
    trackEvent("Pagamento Online Criacao Acessado", {
      tipoPagamentoOnline: getOnlinePaymentType(),
      origemPagamentoOnline: origin,
    });
  }, [getOnlinePaymentType, origin]);

  const getDefaultAmount = (): string => {
    if (appointment?.compromisso) {
      return formatCurrencyString(String(appointment.compromisso.valor * 100));
    }

    return "";
  };

  const form = useForm<CreatePaymentFormValues>({
    resolver: zodResolver(CreatePaymentFormSchema),
    defaultValues: {
      patientName: paymentFormAtom.patientName
        ? paymentFormAtom.patientName
        : appointment?.nome ?? "",
      email: paymentFormAtom.email ? paymentFormAtom.email : appointment?.email ?? "",
      phone: paymentFormAtom.phone ? paymentFormAtom.phone : appointment?.telefone ?? "",
      sendPaymentAutomatically: paymentFormAtom.sendPaymentAutomatically,
      value: paymentFormAtom.value ? paymentFormAtom.value : getDefaultAmount(),
      serviceName: paymentFormAtom.serviceName
        ? paymentFormAtom.serviceName
        : appointment?.compromisso?.nome ?? "",
      paymentMethods: paymentFormAtom.paymentMethods,
      installments: paymentFormAtom.installments,
    },
  });

  const inputsInstallments: SelectDrawerItemProps<number>[] = [
    { label: "1x sem juros", value: 1 },
    { label: "2x sem juros", value: 2 },
    { label: "3x sem juros", value: 3 },
    { label: "4x sem juros", value: 4 },
    { label: "5x sem juros", value: 5 },
    { label: "6x sem juros", value: 6 },
    { label: "7x sem juros", value: 7 },
    { label: "8x sem juros", value: 8 },
    { label: "9x sem juros", value: 9 },
    { label: "10x sem juros", value: 10 },
    { label: "11x sem juros", value: 11 },
    { label: "12x sem juros", value: 12 },
  ];

  const paymentMethodsForm = form.watch("paymentMethods");

  const onSubmit = (formData: CreatePaymentFormValues): void => {
    setPaymentFormAtom(formData);

    void navigate({
      to: "/settings/payments/create/confirmation",
      search: {
        appointmentId: appointment?.codAgendamento,
        appointmentType: appointment?.compromisso?.codUsuarioCompromisso,
        origin: origin ?? "",
        paymentOnlineType: getOnlinePaymentType(),
      },
    });
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit} className="gap-4">
        <h2 className="text-lg font-medium">Para quem será a cobrança?</h2>

        <FormField
          control={form.control}
          name="patientName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput
                  IconLeft={User}
                  title="Nome do paciente"
                  placeholder="Digite aqui"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputEmail {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputPhone title="Celular" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        {showCheckboxSendPaymentAutomatically ? (
          <FormField
            render={({ field }) => (
              <CpsCheckboxV2
                label="Enviar o pagamento para o paciente por WhatsApp e E-mail."
                className="bg-base p-0 py-2"
                onCheckedChange={field.onChange}
                value={String(field.value)}
                defaultChecked={form.watch("sendPaymentAutomatically")}
              />
            )}
            name="sendPaymentAutomatically"
          />
        ) : null}

        <h2 className="text-lg font-medium">Detalhes do pagamento</h2>

        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputCurrency title="Valor" placeholder="R$ 0,00" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="serviceName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsInput title="Nome do serviço" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <div>
          <fieldset className="font-medium">Forma de pagamento</fieldset>
          <FormField
            control={form.control}
            name="paymentMethods"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <OnlinePaymentTypesCheckboxGroup data={paymentTypes} field={field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        {paymentMethodsForm.some((method) => method.name === "credit_card") ? (
          <FormField
            control={form.control}
            name="installments"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <SelectInput
                    items={inputsInstallments}
                    title="Parcelamento no cartão de crédito em"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ) : null}

        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
