import { type ReactNode } from "react";
import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface CustomerContactDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
  drawerTitle: string;
}

export const CustomerContactDrawer = ({
  open,
  onOpenChange: setOpen,
  children,
  drawerTitle,
}: CustomerContactDrawerProps): JSX.Element => {
  return (
    <TooltipDrawerRoot open={open} setOpen={setOpen}>
      <div className="px-4">
        <TooltipDrawerTitle>{drawerTitle}</TooltipDrawerTitle>
        <TooltipDrawerBody>
          <div className="mt-4 mb-10">{children}</div>
        </TooltipDrawerBody>
      </div>
    </TooltipDrawerRoot>
  );
};
