import { Search } from "@repo/icons";
import { CpsInput } from "corpus";
import Fuse from "fuse.js";
import type { ChangeEvent } from "react";

interface SearchInputProps<T> {
  items: T[];
  onSearch: (items: T[]) => void;
  searchKeys: string[];
  placeholder?: string;
}

export const SearchInput = <T,>({
  items,
  onSearch,
  searchKeys,
  placeholder = "Buscar",
}: SearchInputProps<T>): JSX.Element => {
  const fuseOptions = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.3,
    keys: searchKeys,
  };
  const fuse = new Fuse(items, fuseOptions);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value.length === 0) {
      onSearch(items);
      return;
    }

    const results = fuse.search(value);
    const itemsResult = results.map((result) => result.item);
    onSearch(itemsResult);
  };

  return (
    <CpsInput IconLeft={Search} onChange={handleInputChange} placeholder={placeholder} />
  );
};
