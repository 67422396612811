import { useRouter, useSearch } from "@tanstack/react-router";
import {
  WaitingRoomAppointmentMenuQueryDocument,
  WaitingRoomPageQueryDocument,
} from "@repo/graphql-types/graphql";
import { AppointmentCancelDrawer } from "@/components/appointment-cancel-drawer";
import { FlexiblePriceAppointmentCancelDrawer } from "@/components/flexible-price-appointment-cancel-drawer";
import { FlexiblePriceAppointmentRescheduleDrawer } from "@/components/flexible-price-appointment-reschedule-drawer";
import { TreatedAppointmentDrawer } from "@/components/waiting-room-confirm-treated-appointment-drawer";
import { UntreatedAppointmentDrawer } from "@/components/waiting-room-confirm-untreated-appointment-drawer";
import { type WaitingRoomSearch } from "@/components/waiting-room-appointment-list";
import { useInvalidateQuery } from "@/hooks/use-graphql";

export const WaitingRoomDrawers = (): JSX.Element => {
  const params: WaitingRoomSearch = useSearch({ strict: false });
  const router = useRouter();

  const invalidateWaitingRoomQuery = useInvalidateQuery(WaitingRoomPageQueryDocument);
  const invalidateWaitingRoomMenuQuery = useInvalidateQuery(
    WaitingRoomAppointmentMenuQueryDocument,
  );

  const handleShowDrawer = (value: boolean, action: string): void => {
    if (!value && params.action === action) {
      invalidateWaitingRoomQuery();
      invalidateWaitingRoomMenuQuery();
      router.history.go(-2);
    }
  };
  const setShowCancelDrawer = (value: boolean): void => {
    handleShowDrawer(value, "cancel-appointment");
  };

  const setShowTreatedDrawer = (value: boolean): void => {
    handleShowDrawer(value, "treated-appointment-drawer");
  };

  const setShowUntreatedDrawer = (value: boolean): void => {
    handleShowDrawer(value, "untreated-appointment-drawer");
  };

  const onAppointmentCancelSuccess = (): void => {
    invalidateWaitingRoomQuery();

    router.history.go(-2);
  };

  const handleCloseDrawer = (action: string): void => {
    if (params.action === action) {
      router.history.back();
    }
  };

  return (
    <>
      <AppointmentCancelDrawer
        codAgendamento={Number(params.appointmentId ?? 0)}
        setShowDrawer={setShowCancelDrawer}
        showDrawer={params.action === "cancel-appointment"}
        onClose={() => {
          handleCloseDrawer("cancel-appointment");
        }}
      />
      <TreatedAppointmentDrawer
        open={params.action === "treated-appointment-drawer"}
        setOpen={setShowTreatedDrawer}
        onClose={() => {
          handleCloseDrawer("treated-appointment-drawer");
        }}
      />
      <UntreatedAppointmentDrawer
        open={params.action === "untreated-appointment-drawer"}
        setOpen={setShowUntreatedDrawer}
        onClose={() => {
          handleCloseDrawer("untreated-appointment-drawer");
        }}
      />

      <FlexiblePriceAppointmentCancelDrawer
        appointmentId={Number(params.appointmentId ?? 0)}
        onCancelSuccess={onAppointmentCancelSuccess}
      />

      <FlexiblePriceAppointmentRescheduleDrawer
        appointmentId={Number(params.appointmentId ?? 0)}
      />
    </>
  );
};
