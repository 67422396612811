import { Copy } from "@repo/icons";
import { CpsInput, CpsToast } from "corpus";
import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { useState } from "react";

export const UserTrackableLinksDetailsLinkInputFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinksDetailsLinkInputFragment on usuariosLinksRastreamentos {
    link
  }
`);

interface UserTrackableLinksDetailsLinkInputProps {
  data: FragmentType<typeof UserTrackableLinksDetailsLinkInputFragment>;
}

export const UserTrackableLinksDetailsLinkInput = ({
  data,
}: UserTrackableLinksDetailsLinkInputProps): JSX.Element => {
  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const fragmentData = useFragment(UserTrackableLinksDetailsLinkInputFragment, data);

  const { link } = fragmentData;

  const onShareButtonClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(link);
    setOpenCopiedLinkToast(true);
  };

  return (
    <>
      <CpsInput
        title="Link de agendamento"
        IconRight={Copy}
        iconRightClassName="fill-neutral-500 cursor-pointer"
        onClick={() => void onShareButtonClick()}
        data-testid="copy-trackable-link-input"
        value={link}
        readOnly
      />

      <CpsToast
        title="Link copiado"
        description="Seu link de campanha foi copiado com sucesso"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={10}
      />
    </>
  );
};
