import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Trash } from "@repo/icons";
import { formatWithZonedDate, getCurrentDate } from "@repo/lib";
import { differenceInDays } from "date-fns";
import { useRouter } from "@tanstack/react-router";
import { Item, ItemContent, ItemContentEnd, ItemMainContent } from "@/components/list";

export const FollowUpAppointmentReminderListItemQuery = graphql(/* GraphQL */ `
  fragment FollowUpAppointmentReminderListItemQuery on lembretes {
    codLembrete
    paciente {
      nome
    }
    dataParaEnvio
    tipoDeAtendimento: usuarioCompromisso {
      nome
    }
  }
`);

interface FollowUpAppointmentReminderListItemProps {
  data: FragmentType<typeof FollowUpAppointmentReminderListItemQuery>;
}

const formatReminderText = (days: number, sendData: string): string => {
  if (days > 31) {
    return formatWithZonedDate(sendData, "dd/MM/yyyy");
  }
  if (days === 0) {
    return "Hoje";
  }
  if (days === 1) {
    return `Em ${days} dia`;
  }
  return `Em ${days} dias`;
};

export const FollowUpAppointmentReminderListItem = ({
  data,
}: FollowUpAppointmentReminderListItemProps): JSX.Element => {
  const queryData = useFragment(FollowUpAppointmentReminderListItemQuery, data);
  const router = useRouter();

  const today = getCurrentDate();
  const timeToSendReminder = differenceInDays(new Date(queryData.dataParaEnvio), today);

  const textTimeToSendReminder = formatReminderText(
    timeToSendReminder,
    queryData.dataParaEnvio,
  );

  const navigateToDeleteReminder = async (): Promise<void> => {
    await router.navigate({
      search: {
        codLembrete: queryData.codLembrete,
      },
    });
  };

  return (
    <Item>
      <ItemContent>
        <div className="text-sm text-neutral-500">{textTimeToSendReminder}</div>
        <ItemMainContent>{queryData.paciente.nome}</ItemMainContent>
        <div className="text-sm mt-2 text-neutral-500">
          {queryData.tipoDeAtendimento?.nome}
        </div>
      </ItemContent>
      <ItemContentEnd>
        <button
          type="button"
          onClick={() => {
            void navigateToDeleteReminder();
          }}
        >
          <Trash size={24} className="" />
        </button>
      </ItemContentEnd>
    </Item>
  );
};
