import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { type ChangeEvent, useState } from "react";
import { FemaleDoctorWithClipboard, ExclamationCircle, Search } from "@repo/icons";
import { Link, useRouter, useSearch } from "@tanstack/react-router";
import { CpsInput } from "corpus";
import { WaitingRoomAppointmentListItem } from "@/components/waiting-room-appointment-list-item";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { WaitingRoomAppointmentAlert } from "@/components/waiting-room-alert-change-room.tsx";
import { useFuse } from "@/hooks/use-fuse";

export const WaitingRoomAppointmentListQueryFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentListQueryFragment on query_root {
    agendamentos(where: $whereBuild, order_by: { horaInicio: asc }) {
      codAgendamento
      horaFim
      agendamentoPreAlocacao: AgendamentoPreAlocacao {
        UnidadeSala {
          TipoSala {
            nome
            codTipoSala
          }
        }
      }
      agendamentoAlocacaoAtiva: AgendamentosAlocacoesAtivas {
        UnidadeSala {
          TipoSala {
            nome
            codTipoSala
          }
        }
      }
      compromisso: UsuarioCompromisso {
        UsuariosCompromissosTiposSalas {
          codTipoSala
          codUsuarioCompromisso
          prioridade
        }
      }
      ...WaitingRoomAppointmentListItemFragment
    }
  }
`);

interface WaitingRoomAppointmentListItemProps {
  data: FragmentType<typeof WaitingRoomAppointmentListQueryFragment>;
}
export interface WaitingRoomSearch {
  action?: string;
  appointmentId?: number;
  status?: number;
  checkin?: number;
}

export const WaitingRoomAppointmentList = ({
  data,
}: WaitingRoomAppointmentListItemProps): JSX.Element => {
  const params: WaitingRoomSearch = useSearch({ from: "/waiting-room/" });

  const { agendamentos: appointments } = useFragment(
    WaitingRoomAppointmentListQueryFragment,
    data,
  );

  const [input, setInput] = useState("");

  const router = useRouter();

  let content;

  const paramsExist = (): boolean =>
    params.checkin !== undefined || params.status !== undefined;

  const results = useFuse({
    items: appointments,
    keys: ["paciente.nome", "unidade.nome", "compromisso.nome", "horaInicio", "horaFim"],
    input,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInput(value);
  };

  if (appointments.length > 0) {
    content = (
      <>
        <CpsInput IconLeft={Search} onChange={handleInputChange} />
        <ul className="flex flex-col gap-4 mt-4 pb-6">
          {results.map((appointment, index) => (
            <li key={appointment.item.codAgendamento} className="gap-4 flex flex-col">
              <WaitingRoomAppointmentListItem data={appointment.item} />
              <WaitingRoomAppointmentAlert appointments={appointments} index={index} />
            </li>
          ))}
        </ul>
      </>
    );
  } else if (appointments.length === 0 && paramsExist()) {
    content = (
      <EmptyStateRoot>
        <EmptyStateIcon icon={ExclamationCircle} />
        <EmptyStateTitle>Nenhum agendamento encontrado</EmptyStateTitle>
        <EmptyStateDescription>
          Tente redefinir ou ajustar os filtros e realizar a busca novamente.
        </EmptyStateDescription>
        <EmptyStateButton
          onClick={() =>
            void router.navigate({
              search: {
                ...params,
                checkin: undefined,
                status: undefined,
              },
            })
          }
          fullWidth
        >
          Limpar filtros
        </EmptyStateButton>
      </EmptyStateRoot>
    );
  } else {
    content = (
      <EmptyStateRoot>
        <EmptyStateImage
          icon={FemaleDoctorWithClipboard}
          alt="Imagem de nenhum paciente agendado"
        />
        <EmptyStateTitle>Você não tem pacientes agendados para hoje.</EmptyStateTitle>
        <Link to="/appointment/create" className="w-full">
          <EmptyStateButton fullWidth>Agendar agora</EmptyStateButton>
        </Link>
      </EmptyStateRoot>
    );
  }

  return content;
};
