import {
  TooltipDrawerRoot,
  TooltipDrawerTitle,
  TooltipDrawerBody,
} from "@/components/tooltip-drawer";

interface CreatePaymentTooltipDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CreatePaymentTooltipDrawer = ({
  open,
  setOpen,
}: CreatePaymentTooltipDrawerProps): JSX.Element => {
  return (
    <TooltipDrawerRoot setOpen={setOpen} open={open}>
      <TooltipDrawerTitle>Criar pagamento avulso</TooltipDrawerTitle>
      <TooltipDrawerBody>
        <p>
          Utilizando pagamentos avulsos, você pode cobrar por serviços profissionais
          realizados fora da Livance (consultas, procedimentos, cirurgias, etc), com a
          vantagem de visualizar todos os seus recebimentos consolidados na mesma conta,
          através do relatório de Pagamentos e Recebimentos disponível no APP da Livance.
        </p>
      </TooltipDrawerBody>
    </TooltipDrawerRoot>
  );
};
