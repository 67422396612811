import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Link } from "@tanstack/react-router";
import { DoctorWithStethoAndClipboard } from "@repo/icons";
import { Button } from "@/components/button";
import {
  EmptyStateButton,
  EmptyStateImage,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { ListRoot } from "@/components/list";
import { UserTrackableLinksListItem } from "@/components/user-trackable-links/user-trackable-links-list-item";

/**
 * Este fragmento GraphQL é usado para buscar uma lista de links rastreáveis de usuários.
 *
 * Ele ordena os resultados pelo campo 'createdAt' em ordem descendente.
 */
export const UserTrackableLinksQueryFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinksQueryFragment on query_root {
    usuariosLinksRastreamentos(order_by: { dataCadastro: desc }) {
      codUsuarioLinkRastreamento
      ...UserTrackableLinkListItemQueryFragment
    }
  }
`);

export const UserTrackableLinksList = (props: {
  userTrackableLinkList: FragmentType<typeof UserTrackableLinksQueryFragment>;
}): JSX.Element => {
  const { usuariosLinksRastreamentos } = useFragment(
    UserTrackableLinksQueryFragment,
    props.userTrackableLinkList,
  );

  return (
    <div className="justify-center items-center">
      {usuariosLinksRastreamentos.length > 0 ? (
        <>
          <ListRoot>
            {usuariosLinksRastreamentos.map((userTrackableLink) => (
              <UserTrackableLinksListItem
                userTrackableLinkItem={userTrackableLink}
                key={userTrackableLink.codUsuarioLinkRastreamento}
              />
            ))}
          </ListRoot>
          <div className="sticky bottom-0 pb-4">
            <Link to="/settings/livance-via/user-trackable-links/create">
              <Button fullWidth>Criar nova campanha</Button>
            </Link>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-between h-full items-center bg-white mt-8">
          <EmptyStateRoot>
            <EmptyStateImage
              icon={DoctorWithStethoAndClipboard}
              alt="Imagem da lista de campanhas sem campanhas cadastradas"
            />
            <EmptyStateTitle>Você não tem campanhas criadas</EmptyStateTitle>
            <Link
              to="/settings/livance-via/user-trackable-links/create"
              className="w-full"
            >
              <EmptyStateButton fullWidth>Criar nova campanha</EmptyStateButton>
            </Link>
          </EmptyStateRoot>
        </div>
      )}
    </div>
  );
};
