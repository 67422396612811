import { Clock, Phone, Left, Smile, User1, Doctor } from "@repo/icons";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { HeaderUpButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { Page } from "@/components/page";
import { CodFunctionality } from "@/enums/cod-functionality";

export const LivanceVIAMenuPage = (): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });

  const showPRM = user.funcionalidadesContratadas.some(
    (item) => item.codFuncionalidade === CodFunctionality.PRM.valueOf() && item.ativo,
  );

  const showCustomerReport = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === CodFunctionality.CustomerContactOverview.valueOf() &&
      item.ativo,
  );

  const showAppointmentReport = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === CodFunctionality.CustomerAppointmentOverview.valueOf() &&
      item.ativo,
  );

  const showPersonalizeAppointment = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === CodFunctionality.PersonalizeAppointment.valueOf() &&
      item.ativo,
  );



  const menuItens: (MenuListItem & { show: boolean })[] = [
    {
      name: "Relatório de consultas",
      Icon: Doctor,
      route: "/settings/livance-via/customer-appointment-overview",
      show: showAppointmentReport,
      description: "Monitore o fluxo de consultas agendadas e realizadas",
    },
    {
      name: "Relatório de contatos",
      Icon: Phone,
      route: "/settings/livance-via/customer-contact-overview",
      show: showCustomerReport,
      description: "Acompanhe o progresso dos contatos do seu consultório",
    },
    {
      name: "Minhas campanhas",
      Icon: User1,
      route: "/settings/livance-via/user-trackable-links",
      description:
        "Crie links exclusivos para suas campanhas de marketing e acompanhe o retorno dos investimentos",
      show: true,
    },
    {
      name: "Lembretes programados",
      Icon: Clock,
      route: "/settings/livance-via/follow-up-appointment-reminder",
      description: "Veja os pacientes com lembretes de retorno programados por você",
      show: showPRM,
    },
    {
      name: "Personalizar atendimento",
      Icon: Smile,
      route: "/settings/livance-via/secretary-details",
      description:
        "Forneça informações para as secretárias atenderem de forma personalizada e agendarem mais",
      show: showPersonalizeAppointment,
      showSoonTag: false,
    },
  ];

  const activeMenuItens = menuItens.filter((item) => item.show);

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Livance VIA" />
      </HeaderRoot>
      <Page className="pt-2">
        <MenuList menuItems={activeMenuItens} />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/livance-via/")({
  component: LivanceVIAMenuPage,
});
