import { CpsAlert } from "corpus";

export const UserTrackableLinkReportDetailAlerts = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-4">
      <CpsAlert
        type="info"
        title="Atualização dos dados"
        description="Os dados do relatório de campanha podem levar até 24 horas para serem atualizados."
      />

      <CpsAlert
        type="info"
        title="Porcentagem"
        description="Mostra o volume de cada etapa em relação ao número total de acessos a campanha."
      />
    </div>
  );
};
