import { Check } from "@repo/icons";

interface TermsCheckboxProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

const TERMS_URL =
  "https://ajuda.livance.com.br/hc/pt-br/articles/30982622126739-Condi%C3%A7%C3%B5es-Gerais-para-Migra%C3%A7%C3%A3o-de-Planos-de-Mensalidade-da-Livance";

export const ChangePlanCheckboxTerms = ({
  isChecked,
  onChange,
}: TermsCheckboxProps): JSX.Element => {
  return (
    <label className="relative flex flex-row items-center gap-2 border-neutral-100 pb-4 last:pb-0 cursor-pointer group">
      <div className="relative flex items-center">
        <input
          type="checkbox"
          className="w-[20px] h-[20px] border-[2px] border-neutral-200 rounded-lg group-has-[:checked]:bg-secondary-400 group-has-[:checked]:border-secondary-400 appearance-none flex items-center justify-center focus:outline-none bg-white"
          name="agree-terms"
          onChange={() => onChange(!isChecked)}
          checked={isChecked}
        />
        <span className="absolute inset-0 flex items-center justify-center group group-has-[:checked]:text-white">
          <Check size={16} className="fill-white" />
        </span>
      </div>
      <span className="text-neutral-500 font-medium text-sm">
        Li e aceito os{" "}
        <a
          href={TERMS_URL}
          target="_blank"
          className="text-helper-600 underline"
          rel="noopener"
        >
          termos e condições
        </a>
      </span>
    </label>
  );
};
