import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { RescuedUnscheduledAppointments } from "@/components/customer-contact-overview/rescued-unscheduled-appointments";
import { RescuedCancelledAppointments } from "@/components/customer-contact-overview/rescued-cancelled-appointments";
import { RescuedNoShowAppointments } from "@/components/customer-contact-overview/rescued-no-show-appointments";
import { RescuedAppointmentsType } from "@/enums/rescued-appointments-types";

export const RescuedAppointmentsFragment = graphql(/* GraphQL */ `
  fragment RescuedAppointmentsFragment on AnalyticsMembroTotalDeAgendamentosResgatadosPorFluxo {
    data {
      month: mes
      rescuedAppointmentType: fluxo
      appointmentsRescuedSum: agendamentosRecuperados
      sentMessagesSum: totalMensagens
    }
  }
`);

interface RescuedAppointmentsData {
  month: string;
  rescuedAppointmentType: RescuedAppointmentsType;
  appointmentsRescuedSum: number;
  sentMessagesSum: number;
}

interface RescuedAppointmentsProps {
  fragmentData: FragmentType<typeof RescuedAppointmentsFragment>;
  selectedMonth: string;
}

export const RescuedAppointments = ({
  fragmentData,
  selectedMonth,
}: RescuedAppointmentsProps): JSX.Element => {
  const { data: rescuedAppointmentsData } = useFragment(
    RescuedAppointmentsFragment,
    fragmentData,
  );

  const filteredData = rescuedAppointmentsData.filter(
    (item) => item?.month === selectedMonth,
  );

  const noShowAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.NoShow,
  );

  const cancelledAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.Cancelled,
  );

  const unscheduledAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.Unscheduled,
  );

  return (
    <div className="flex flex-col gap-6">
      <RescuedUnscheduledAppointments
        data={unscheduledAppointment as RescuedAppointmentsData}
      />
      <RescuedCancelledAppointments
        data={cancelledAppointment as RescuedAppointmentsData}
      />
      <RescuedNoShowAppointments data={noShowAppointment as RescuedAppointmentsData} />
    </div>
  );
};
