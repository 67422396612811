import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { formatWithZonedDate, ReminderType } from "@repo/lib";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { FollowUpAppointmentReminderList } from "@/components/follow-up-appointment-reminder-list";
import { DeleteFollowUpAppointmentReminderDrawer } from "@/components/delete-follow-up-appointment-reminder-drawer";

const FollowUpAppointmentReminderPageQuery = graphql(/* GraphQL */ `
  query FollowUpAppointmentReminderPageQuery(
    $codTipoLembreteRetorno: Int!
    $hoje: datetime2!
  ) {
    ...FollowUpAppointmentReminderListQuery
  }
`);

export const FollowUpAppointmentReminderPage = (): JSX.Element => {
  const queryResult = useGraphQL(FollowUpAppointmentReminderPageQuery, {
    codTipoLembreteRetorno: ReminderType.FollowUpAppointment.valueOf(),
    hoje: formatWithZonedDate(new Date()),
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Mensagens Programadas" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <FollowUpAppointmentReminderList data={data} /> : null}
          <DeleteFollowUpAppointmentReminderDrawer />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/livance-via/follow-up-appointment-reminder/",
)({
  component: FollowUpAppointmentReminderPage,
});
