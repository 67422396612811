import { CpsAlert } from "corpus";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Edit1 } from "@repo/icons";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";
import {
  Item,
  ItemContent,
  ItemContentEnd,
  ItemMainContent,
  ListRoot,
} from "@/components/list";

export const PersonalizeAppointmentFormFragment = graphql(/* GraphQL */ `
  fragment PersonalizeAppointmentFormFragment on query_root {
    personalizeAppointment: usuariosProfissionaisOrientacoesSecretarias {
      professionalInfos: informacoesSobreProfissional
      appointmentInfos: informacoesSobreAgendamento
      restrictions: informacoesSobreRestricoes
      faq: informacoesDuvidasFrequentes
    }
  }
`);

export const PersonalizeAppointmentForm = ({
  data,
}: {
  data: FragmentType<typeof PersonalizeAppointmentFormFragment> | null | undefined;
}): JSX.Element => {
  const fragmentData = useFragment(PersonalizeAppointmentFormFragment, data);
  const professionalData = fragmentData?.personalizeAppointment[0];
  return (
    <>
      <PageTitleRoot>
        <PageTitleMainContent>Personalizar atendimento</PageTitleMainContent>
        <PageTitleDescription>
          Garanta que as secretárias tenham todas as informações para fazer os seus
          agendamentos.
        </PageTitleDescription>
      </PageTitleRoot>
      <div className="my-4">
        <CpsAlert
          type="info"
          title="Essas informações serão vistas apenas pelo nosso time de secretárias."
        />
      </div>
      <ListRoot>
        <Item
          linkProps={{ to: "/settings/livance-via/secretary-details/professional-infos" }}
        >
          <ItemContent>
            <ItemMainContent>Qual seu diferencial como Profissional?</ItemMainContent>
            <p className="text-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.professionalInfos
                ? professionalData.professionalInfos
                : "Escreva aqui 3 motivos pelos quais os pacientes deveriam agendar com você"}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item
          linkProps={{ to: "/settings/livance-via/secretary-details/appointment-infos" }}
        >
          <ItemContent>
            <ItemMainContent>Orientações para Agendamento</ItemMainContent>
            <p className="text-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.appointmentInfos
                ? professionalData.appointmentInfos
                : "Descreva aqui como seus agendamentos deveriam ser realizados, se há um dia de preferência ou unidade preferencial."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item linkProps={{ to: "/settings/livance-via/secretary-details/restrictions" }}>
          <ItemContent>
            <ItemMainContent>Restrições de Agendamento</ItemMainContent>
            <p className="text-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.restrictions
                ? professionalData.restrictions
                : "Escreva aqui motivos de contatos que devemos evitar ou pacientes restritos."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item linkProps={{ to: "/settings/livance-via/secretary-details/faq" }}>
          <ItemContent>
            <ItemMainContent>Anotações de Agendamento</ItemMainContent>
            <p className="text-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.faq ? professionalData.faq : "Registre aqui dúvidas recebidas dos pacientes"}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
      </ListRoot>
    </>
  );
};
