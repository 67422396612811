import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { HeaderRoot, HeaderUpButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const CustomerAppointmentOverviewPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Relatório de Consultas" align="center" />
      </HeaderRoot>
      <Page>Início do novo relatório</Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/livance-via/customer-appointment-overview/",
)({
  component: CustomerAppointmentOverviewPage,
});
