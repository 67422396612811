import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { ENVIRONMENT, VITE_SENTRY_DSN } from "./config";
import { getAppInfo } from "./app-info";

const initSentry = async (): Promise<void> => {
  const appInfo = await getAppInfo();

  const tracesSampleRate = 0.01; // 1%
  const replaysOnErrorSampleRate = ENVIRONMENT === "production" ? 1.0 : 0; // 100% ou 0%

  const replay = SentryReact.replayIntegration({
    maskAllText: false,
    blockAllMedia: true,
  });

  const feedback = SentryReact.feedbackIntegration({
    colorScheme: "light",
    themeLight: {
      accentBackground: "#00658a",
    },
    showBranding: false,
    useSentryUser: {
      email: "email",
      name: "fullName",
    },
    autoInject: false,
    showName: false,
    showEmail: false,
    formTitle: "Nos ajude a melhorar!",
    submitButtonLabel: "Enviar Feedback",
    cancelButtonLabel: "Cancelar",
    triggerLabel: "Enviar Feedback",
    enableScreenshot: false,
    nameLabel: "Nome",
    namePlaceholder: "Seu nome",
    emailLabel: "Email",
    emailPlaceholder: "",
    isRequiredLabel: "(obrigatório)",
    messageLabel: "Descrição",
    messagePlaceholder: "Deixe aqui o seu feedback.",
    successMessageText: "Obrigado por nos ajudar!",
  });

  Sentry.init(
    {
      environment: ENVIRONMENT,
      dsn: VITE_SENTRY_DSN,
      release: `${appInfo.id}@${appInfo.version}+${appInfo.build}`,
      dist: appInfo.version,
      autoSessionTracking: true,
      // @ts-expect-error - TS2322 Mismatch entre as versões do sentry e do react
      integrations: [replay, feedback],
      // Performance Monitoring
      enableTracing: true,
      tracesSampleRate,
      // Session Replay
      replaysSessionSampleRate: 0.01, // 1%
      replaysOnErrorSampleRate,
      tracePropagationTargets: [
        "localhost",
        "https://hasura.livance.com.br",
        "https://app.livance.com.br",
        "https://painel.livance.com.br",
      ],
      debug: false,
    },
    SentryReact.init,
  );
};

export { initSentry, Sentry };
