import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";

export const ScheduleListItemCardUnitFragment = graphql(/* GraphQL */ `
  fragment ScheduleListItemCardUnitFragment on usuariosAgendas {
    agendasUnidades: tbUsuariosAgendasUnidades(where: { ativo: { _eq: true } }) {
      Unidade {
        sigla
        nomeLimpo
      }
    }
  }
`);

export const ScheduleListItemCardUnit = (props: {
  schedule: FragmentType<typeof ScheduleListItemCardUnitFragment>;
}): JSX.Element => {
  const { agendasUnidades } = useFragment(
    ScheduleListItemCardUnitFragment,
    props.schedule,
  );

  const MAX_NUMBER_OF_DISPLAYED_UNITS = 2;

  const scheduleUnits = (): typeof agendasUnidades => {
    return agendasUnidades.slice(0, MAX_NUMBER_OF_DISPLAYED_UNITS);
  };

  const totalOfScheduleUnits = agendasUnidades.length;

  const shouldShowMore = totalOfScheduleUnits > MAX_NUMBER_OF_DISPLAYED_UNITS;

  const units = scheduleUnits()
    .map((unit) => (unit.Unidade?.sigla ? unit.Unidade.sigla : unit.Unidade?.nomeLimpo))
    .join(" | ");

  const buildContent = (): string => {
    let content = units;

    if (shouldShowMore)
      content += ` | +${totalOfScheduleUnits - MAX_NUMBER_OF_DISPLAYED_UNITS}`;

    return content;
  };

  return <p className="text-gray-600 font-normal">{buildContent()}</p>;
};
