import { CpsInput, CpsTextArea, CpsToast } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import {
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  convertBrazilianCurrencyToNumber,
  formatIgnoringTimezone,
  sanitizeAndFormatAmount,
} from "@repo/lib";
import { useState } from "react";
import { CircleCheck } from "@repo/icons";
import { GetOneLivUserBasicInfoDocument } from "@repo/graphql-types/graphql";
import {
  MessageDrawerRoot,
  MessageDrawerBody,
  MessageDrawerActions,
  MessageDrawerActionButton,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { TextToggle } from "@/components/text-toggle";
import { PronounSelectInput } from "@/components/pronoun-select-input";
import { UfSelectInput } from "@/components/uf-select-input";
import { TimeInput } from "@/components/time-input";
import { InputCurrency } from "@/components/input-currency";
import { AcceptedPaymentMethodsSelectInput } from "@/components/accepted-payment-methods-select-input";
import { OneLivChangeProfilePictureButton } from "@/components/oneliv/oneliv-change-profile-picture-button";
import { OneLivProfilePicture } from "@/components/oneliv/oneliv-profile-picture";
import {
  type oneLivFormBasicInfo,
  oneLivFormBasicInfoWithValidation,
} from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { OneLivSpecialtiesInput } from "@/components/oneliv/oneliv-specialties-input";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { OneLivDisableProfileDrawer } from "@/components/oneliv/oneliv-disable-profile-drawer";
import { trackEvent } from "@/lib/tracking";
import { OneLivProfessionalCouncilSelectInput } from "@/components/oneliv/oneliv-professional-council-select-input";

export const OneLivBasicInfoFormFragment = graphql(/* GraphQL */ `
  fragment OneLivBasicInfoFormFragment on query_root {
    locus_membro {
      id
      apelido
      pronome
      ufConselhoClasse
      numeroConselhoClasse
      duracaoInicialConsulta
      valorInicialConsulta
      formasPagamento
      resumo
      fotoUrl
      ativo
      id
      idConselhoProfissional
      membroEspecialidades {
        rqe
        prioridade
        idEspecialidade
      }
    }
    ...OneLivSpecialtiesInputFragment
    ...OneLivProfessionalCouncilSelectInputFragment
  }
`);

const ActivateMemberProfileMutation = graphql(`
  mutation ActivateMemberProfileMutation(
    $codUsuario: Int!
    $set: locus_membro_set_input!
  ) {
    updateMember: update_locus_membro(
      where: { codUsuario: { _eq: $codUsuario } }
      _set: $set
    ) {
      affected_rows
    }
  }
`);

const UpsertBasicProfileMutation = graphql(`
  mutation UpsertBasicProfile($input: LocusUpsertBasicProfileInput!) {
    LocusUpsertBasicProfile(arg1: $input) {
      id
    }
  }
`);

export type OneLivFormBasicInfoValues = z.infer<typeof oneLivFormBasicInfo>;

const TIMER_CONFIGS = {
  minHour: 0,
  maxHour: 3,
  minMinutes: 0,
  maxMinutes: 59,
  stepHour: 1,
  stepMinutes: 5,
};

interface BasicInfoFormProps {
  data?: FragmentType<typeof OneLivBasicInfoFormFragment>;
}

export const OneLivBasicInfoForm = ({ data }: BasicInfoFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivBasicInfoFormFragment, data);

  const { mutateAsync: executeUpsertBasicProfileMutation } =
    useGraphQLMutationWithErrorHandler(UpsertBasicProfileMutation);

  const { mutateAsync: executeActivateMemberProfileMutation } =
    useGraphQLMutationWithErrorHandler(ActivateMemberProfileMutation);

  const navigate = useNavigate();

  const router = useRouter();

  const { user } = useRouteContext({ strict: false });

  const [showProfileStatusToast, setShowProfileStatusToast] = useState({
    show: false,
    message: "",
  });

  const { action } = useSearch({
    from: "/oneliv/basic-info",
  });

  const invalidateGetOneLivUserBasicInfo = useInvalidateQuery(
    GetOneLivUserBasicInfoDocument,
  );

  const membro = fragmentResult?.locus_membro[0];

  const isProfileAlreadyCreated = Boolean(membro);

  const initialAppointmentDuration = membro?.duracaoInicialConsulta ?? "";
  const initialAppointmentValue = membro?.valorInicialConsulta
    ? sanitizeAndFormatAmount(String(membro.valorInicialConsulta * 100))
    : "";

  const getDefaultValues = (): OneLivFormBasicInfoValues => {
    if (membro) {
      const specialties = [1, 2, 3].map(
        (priority) =>
          membro.membroEspecialidades.find((x) => x.prioridade === priority) ?? {
            idEspecialidade: 0,
            rqe: undefined,
          },
      );

      return {
        profilePicture: membro.fotoUrl,
        name: membro.apelido,
        pronoun: membro.pronome ?? "",
        initialAppointmentDuration,
        initialAppointmentValue,
        professionalCouncil: membro.idConselhoProfissional ?? 0,
        professionalCouncilState: membro.ufConselhoClasse,
        professionalCouncilNumber: membro.numeroConselhoClasse,
        paymentMethods: membro.formasPagamento ?? "",
        summary: membro.resumo,
        active: membro.ativo,
        firstSpecialty: specialties[0].idEspecialidade,
        firstSpecialtyRQE: specialties[0].rqe ?? "",
        secondSpecialty: specialties[1].idEspecialidade,
        secondSpecialtyRQE: specialties[1].rqe ?? "",
        thirdSpecialty: specialties[2].idEspecialidade,
        thirdSpecialtyRQE: specialties[2].rqe ?? "",
      };
    }

    return {
      name: "",
      active: true,
      pronoun: undefined,
      initialAppointmentDuration,
      initialAppointmentValue,
      professionalCouncil: 0,
      professionalCouncilState: "",
      professionalCouncilNumber: "",
      paymentMethods: "",
      summary: "",
      firstSpecialty: 0,
      firstSpecialtyRQE: "",
      secondSpecialty: 0,
      secondSpecialtyRQE: "",
      thirdSpecialty: 0,
      thirdSpecialtyRQE: "",
    };
  };

  const form = useForm<OneLivFormBasicInfoValues>({
    resolver: zodResolver(oneLivFormBasicInfoWithValidation),
    defaultValues: getDefaultValues(),
  });

  const isModalOverlayOpen: boolean = action === "expand-avatar-image";

  const handleOpenModalOverlay = (value: boolean): void => {
    if (value) {
      void navigate({
        search: { action: "expand-avatar-image" },
      });
    } else if (isModalOverlayOpen) {
      history.back();
    }
  };
  const handleDisableProfileDrawerDismiss = (value: boolean): void => {
    if (!value && action === "disable-profile") {
      form.setValue("active", true);
      router.history.back();
    }
  };

  const handleProfileCreatedDrawerDismiss = (value: boolean): void => {
    if (!value && action === "profile-created") {
      router.history.back();
    }
  };

  const navigateToNextStep = (shouldReplace = false): void => {
    void navigate({
      to: "/oneliv/social-media",
      replace: shouldReplace,
    });
  };

  const onSubmit = async (formData: OneLivFormBasicInfoValues): Promise<void> => {
    const onSuccess = (): void => {
      if (!membro) {
        trackEvent("Oneliv Perfil Criado", {
          codUsuario: user.codUsuario,
        });
      }
      invalidateGetOneLivUserBasicInfo();

      if (isProfileAlreadyCreated) {
        navigateToNextStep();
      } else {
        void navigate({
          search: { action: "profile-created" },
        });
      }
    };

    let fileData;

    if (formData.profilePicture instanceof Blob) {
      fileData = await blobToBase64(formData.profilePicture);
    }

    const specialties = getSpecialtiesArray(formData);

    const mutationInput = {
      idConselhoProfissional: formData.professionalCouncil,
      ufConselhoProfissional: formData.professionalCouncilState,
      ativo: formData.active,
      numeroConselhoProfissional: formData.professionalCouncilNumber,
      duracaoConsulta: formData.initialAppointmentDuration,
      valorConsulta: convertBrazilianCurrencyToNumber(formData.initialAppointmentValue),
      formasPagamento: formData.paymentMethods,
      resumo: formData.summary,
      fotoPerfil: fileData
        ? {
            base64string: fileData.base64,
            size: fileData.size,
            type: fileData.format,
          }
        : undefined,
      nomeExibido: formData.name,
      pronome: formData.pronoun ? formData.pronoun : undefined,
      especialidades: specialties,
    };

    await executeUpsertBasicProfileMutation(
      {
        input: mutationInput,
      },
      { onSuccess },
    );
  };

  const trackChangeStatusEvent = (status: "ativo" | "inativo"): void => {
    trackEvent("Oneliv Perfil Status Alterado", {
      codUsuario: user.codUsuario,
      status,
    });
  };

  const onActivateMemberProfile = async (): Promise<void> => {
    const onSuccess = (): void => {
      trackChangeStatusEvent("ativo");

      setShowProfileStatusToast({
        show: true,
        message: "Perfil ativado com sucesso!",
      });
    };

    await executeActivateMemberProfileMutation(
      {
        codUsuario: user.codUsuario,
        set: {
          ativo: true,
          dataReativacao: formatIgnoringTimezone(new Date()),
        },
      },
      { onSuccess },
    );
  };

  const onDisableMemberSuccess = (): void => {
    trackChangeStatusEvent("inativo");

    setShowProfileStatusToast({
      show: true,
      message: "Perfil desativado com sucesso!",
    });

    router.history.back();
  };

  const isValidSpecialty = (specialty: {
    id?: number;
    rqe?: string;
    prioridade: number;
  }): specialty is { id: number; rqe: string; prioridade: number } => {
    return Boolean(specialty.id);
  };

  const getSpecialtiesArray = (
    formData: OneLivFormBasicInfoValues,
  ): { id: number; rqe: string; prioridade: number }[] => {
    const specialties = [
      { id: formData.firstSpecialty, rqe: formData.firstSpecialtyRQE, prioridade: 1 },
      { id: formData.secondSpecialty, rqe: formData.secondSpecialtyRQE, prioridade: 2 },
      { id: formData.thirdSpecialty, rqe: formData.thirdSpecialtyRQE, prioridade: 3 },
    ];

    return specialties.filter(isValidSpecialty);
  };

  const blobToBase64 = async (
    blob: Blob,
  ): Promise<{ base64: string; format: string; size: number }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result as string;
        const base64 = result.split(",")[1];
        const format = blob.type;
        const size = blob.size;

        resolve({ base64, format, size });
      };

      reader.onerror = reject;

      reader.readAsDataURL(blob);
    });
  };

  const onChangeActivateMemberToggle = (status: boolean): void => {
    if (!status) {
      void navigate({
        search: { action: "disable-profile" },
      });
    } else {
      void onActivateMemberProfile();
    }
  };

  const resetSpecialtiesInputs = (): void => {
    form.setValue("firstSpecialty", 0);
    form.setValue("firstSpecialtyRQE", "");
    form.setValue("secondSpecialty", 0);
    form.setValue("secondSpecialtyRQE", "");
    form.setValue("thirdSpecialty", 0);
    form.setValue("thirdSpecialtyRQE", "");
  };

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={onSubmit}>
          <p className="font-medium text-neutral-600">Dados gerais do profissional</p>
          <div className="flex flex-col items-center gap-4 relative">
            <FormField
              control={form.control}
              name="profilePicture"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <OneLivProfilePicture
                        shouldOpenModalOverlay
                        isModalOverlayOpen={isModalOverlayOpen}
                        setIsModalOverlayOpen={() =>
                          handleOpenModalOverlay(!isModalOverlayOpen)
                        }
                        {...field}
                      />
                      <OneLivChangeProfilePictureButton {...field} />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          {isProfileAlreadyCreated ? (
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <TextToggle
                      label="Perfil ativo"
                      {...field}
                      data-testid="active-toggle"
                      onChange={(
                        event: boolean | React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        field.onChange(event);
                        if (typeof event === "boolean" && membro) {
                          onChangeActivateMemberToggle(event);
                        }
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          ) : null}

          <FormField
            control={form.control}
            name="pronoun"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PronounSelectInput inputMode="text" type="text" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Nome Exibido"
                    required
                    placeholder="Digite aqui"
                    inputMode="text"
                    type="text"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="professionalCouncil"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <OneLivProfessionalCouncilSelectInput
                    required
                    inputMode="numeric"
                    type="number"
                    data={fragmentResult}
                    {...field}
                    onChange={(
                      event: number | React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      field.onChange(event);
                      resetSpecialtiesInputs();
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="professionalCouncilState"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <UfSelectInput
                    title="UF Conselho Profissional"
                    required
                    inputMode="text"
                    type="text"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="professionalCouncilNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Número Conselho Profissional"
                    required
                    placeholder="Digite aqui"
                    inputMode="text"
                    type="text"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="summary"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsTextArea
                    title="Frase de destaque*"
                    placeholder="Digite aqui"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <hr className="text-neutral-200 border-1" />

          <p className="font-medium text-neutral-600">Dados da consulta</p>
          {!membro ? (
            <>
              <FormField
                control={form.control}
                name="initialAppointmentDuration"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TimeInput
                        label="Duração"
                        required
                        timerConfigs={{
                          ...TIMER_CONFIGS,
                          initialHour: Number(
                            initialAppointmentDuration.split(":")[0] ?? "00",
                          ),
                          initialMinutes: Number(
                            initialAppointmentDuration.split(":")[1] ?? "00",
                          ),
                        }}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="initialAppointmentValue"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputCurrency
                        title="Valor inicial da consulta"
                        required
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </>
          ) : null}

          <FormField
            control={form.control}
            name="paymentMethods"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AcceptedPaymentMethodsSelectInput required {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <hr className="text-neutral-200 border-1" />

          <p className="font-medium text-neutral-600">Especialidades</p>

          <OneLivSpecialtiesInput form={form} data={fragmentResult} />

          <FormSubmitButton type="submit">
            {isProfileAlreadyCreated ? "Avançar" : "Criar perfil"}
          </FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <OneLivDisableProfileDrawer
        setOpen={handleDisableProfileDrawerDismiss}
        onDisableSuccess={onDisableMemberSuccess}
      />
      <MessageDrawerRoot
        open={action === "profile-created"}
        setOpen={handleProfileCreatedDrawerDismiss}
        icon={CircleCheck}
        variant="secondary"
      >
        <MessageDrawerTitle>Seu perfil no OneLiv já está ativo!</MessageDrawerTitle>
        <MessageDrawerBody>
          Clique em Avançar para deixar o seu perfil mais completo e atrativo.
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton onClick={() => navigateToNextStep(true)}>
            Avançar
          </MessageDrawerActionButton>
          <MessageDrawerActionButton
            secondary
            onClick={() =>
              void navigate({
                to: "/settings/profile",
              })
            }
          >
            Agora não
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <CpsToast
        title={showProfileStatusToast.message}
        show={showProfileStatusToast.show}
        onClose={() => setShowProfileStatusToast({ show: false, message: "" })}
        type="success"
        duration={10}
      />
    </>
  );
};
