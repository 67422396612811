import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type NuntiusGetCustomerContactManyPeriodSummaryOccurrenceData } from "@repo/graphql-types/graphql";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { noSchedulingReasonMap } from "@/enums/customer-contact/no-scheduling-reason";
import { trackEvent } from "@/lib/tracking";

export const ReasonsForNotSchedulingSessionFragmentQueryFragment = graphql(/* GraphQL */ `
  fragment ReasonsForNotSchedulingSessionFragmentQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
    period
    mostFrequentNotScheduledAppointmentReasons {
      key
      total
      percent
    }
  }
`);

interface ReasonsForNotSchedulingProps {
  data: FragmentType<typeof ReasonsForNotSchedulingSessionFragmentQueryFragment>[];
  selectedMonth: string;
}

export const ReasonsForNotScheduling = ({
  data,
  selectedMonth,
}: ReasonsForNotSchedulingProps): JSX.Element => {
  const fragmentData = useFragment(
    ReasonsForNotSchedulingSessionFragmentQueryFragment,
    data,
  );

  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });
  const router = useRouter();

  const onOpenChange = (openDrawer: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (openDrawer) {
      search.showReasonForNotSchedullingDrawer = openDrawer;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Motivos de não agendamento",
      });

      void router.navigate({ search });
    } else if (searchParams.showReasonForNotSchedullingDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div data-testid="reasons-for-not-scheduling" className="mt-5">
      <div className="my-8 flex flex-col gap-2">
        <CustomerContactSectionHeader onOpenChange={onOpenChange}>
          Motivos de não agendamento
        </CustomerContactSectionHeader>
        {fragmentData.length === 0 ||
        !currentMonthData?.mostFrequentNotScheduledAppointmentReasons.length ||
        currentMonthData.mostFrequentNotScheduledAppointmentReasons.every(
          (x) => x.total === 0,
        ) ? (
          <CustomerContactsOverviewEmptyState />
        ) : (
          <ReasonsForNotSchedulingMonthContent
            data={currentMonthData.mostFrequentNotScheduledAppointmentReasons}
          />
        )}
        <CustomerContactDrawer
          open={Boolean(searchParams.showReasonForNotSchedullingDrawer)}
          onOpenChange={onOpenChange}
          drawerTitle="Motivos de não agendamento"
        >
          <p className="text-md">
            Os motivos de não agendamento são registrados pelo nosso time de secretárias
            com base na interação direta com o paciente, identificando as razões que
            impediram a finalização do agendamento.
          </p>
        </CustomerContactDrawer>
      </div>
    </div>
  );
};

interface ReasonsForNotSchedulingMonthContentProps {
  data: NuntiusGetCustomerContactManyPeriodSummaryOccurrenceData[];
}

const ReasonsForNotSchedulingMonthContent = ({
  data,
}: ReasonsForNotSchedulingMonthContentProps): JSX.Element => {
  return (
    <div className="mt-6 gap-2 flex flex-col">
      {data.map((x, index) => (
        <div
          key={x.key}
          className="bg-neutral-100 rounded-lg h-10 align-middle flex w-auto justify-between items-center px-3 gap-2"
        >
          <div className="flex items-start gap-2">
            <span className="font-medium text-sm w-[9px] text-neutral-600 flex justify-center">
              {`${index + 1}° `}
            </span>
            <span className="font-normal text-xs text-neutral-500">
              {noSchedulingReasonMap[x.key]}
            </span>
          </div>
          <span className="font-medium text-sm items-end text-neutral-600">
            {x.percent}%
          </span>
        </div>
      ))}
    </div>
  );
};
