import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/lib";
import {
  createContext,
  useContext,
  type HTMLAttributes,
  type InputHTMLAttributes,
  useState,
  type FunctionComponent,
  forwardRef,
  type FC,
} from "react";

interface PriorityMultiSelectorRootProps {
  children: React.ReactElement<
    PriorityMultiSelectorTitleProps | PriorityMultiSelectorListProps
  >[];
  name: string;
  initialCheckedValues?: string[];
  onChange?: (value: string[]) => void;
}

export const PriorityMultiSelectorRoot = ({
  children,
  name,
  initialCheckedValues,
  onChange,
}: PriorityMultiSelectorRootProps): JSX.Element => {
  const [checkedValues, setCheckedValues] = useState(initialCheckedValues ?? []);

  const handleOnChange = (value: string, isChecked: boolean, isValid: boolean): void => {
    let updatedValues = [...checkedValues];

    if (isValid) {
      if (isChecked) {
        updatedValues = [...checkedValues, value];
      } else {
        updatedValues = checkedValues.filter((val) => val !== value);
      }
    }

    setCheckedValues(updatedValues);
    onChange && onChange(updatedValues);
  };

  return (
    <PriorityMultiSelectorContext.Provider
      value={{ name, onChange: handleOnChange, checkedValues }}
    >
      <div className="flex flex-col gap-1">{children}</div>
    </PriorityMultiSelectorContext.Provider>
  );
};

interface PriorityMultiSelectorTitleProps extends HTMLAttributes<HTMLButtonElement> {
  children: string;
  required?: boolean;
  onIconClick?: () => void;
  Icon?: FunctionComponent<SVGIconProps>;
}

export const PriorityMultiSelectorTitle: FC<PriorityMultiSelectorTitleProps> = ({
  children,
  required = false,
  onIconClick,
  Icon,
  ...props
}: PriorityMultiSelectorTitleProps) => {
  const { name } = usePriorityMultiSelectorContext();

  return (
    <div className="flex gap-2 items-center mb-1">
      <label className="font-medium" htmlFor={name}>
        {children}
        {required ? "*" : ""}
      </label>

      {Icon && onIconClick ? (
        <button onClick={onIconClick} tabIndex={0} type="button" {...props}>
          <Icon className="fill-neutral-300" size={24} />
        </button>
      ) : null}
    </div>
  );
};

interface PriorityMultiSelectorListProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<PriorityMultiSelectorItemProps>[];
}

export const PriorityMultiSelectorList = ({
  children,
}: PriorityMultiSelectorListProps): JSX.Element => {
  return (
    <div className="bg-neutral-50 rounded-30 p-4 flex flex-col gap-4">{children}</div>
  );
};

interface PriorityMultiSelectorItemProps extends InputHTMLAttributes<HTMLInputElement> {
  children: string;
  isValid?: boolean;
}

export const PriorityMultiSelectorItem = forwardRef<
  HTMLInputElement,
  PriorityMultiSelectorItemProps
>(
  (
    { children, isValid = true, ...props },
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const { name, onChange, checkedValues } = usePriorityMultiSelectorContext();

    const [isChecked, setIsChecked] = useState(
      checkedValues?.includes(String(props.value)) ?? false,
    );

    const buildPriorityText = (): string => {
      const index = checkedValues?.indexOf(String(props.value)) ?? -1;
      return index !== -1 ? String(index + 1) : "";
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (isValid) {
        setIsChecked(e.target.checked);
      }

      onChange && onChange(e.target.value, e.target.checked, isValid);
    };

    return (
      <label
        className="relative flex flex-row items-center gap-4 border-b-2 last:border-0 border-neutral-100 pb-4 last:pb-0 cursor-pointer group"
        htmlFor={props.id}
      >
        <div className="relative flex items-center">
          <input
            type="checkbox"
            className={cn(
              "w-[32px] h-[32px] border-[1px] border-primary-400 rounded-full group-has-[:checked]:bg-primary-400 group-has-[:checked]:border-primary-400 appearance-none flex items-center justify-center focus:outline-none bg-white",
              !isValid && "bg-neutral-100 border-neutral-300",
            )}
            name={name}
            onChange={handleOnChange}
            checked={isChecked}
            ref={ref}
            {...props}
          />
          <span className="absolute inset-0 flex items-center justify-center group group-has-[:checked]:text-white">
            {buildPriorityText()}
          </span>
        </div>
        <span className="group-has-[:checked]:text-neutral-600 text-neutral-500 font-medium">
          {children}
        </span>
      </label>
    );
  },
);

PriorityMultiSelectorItem.displayName = "PriorityMultiSelectorItem";

interface PriorityMultiSelectorContextProps {
  name?: string;
  checkedValues?: string[];
  onChange?: (value: string, isChecked: boolean, isValid: boolean) => void;
}

const PriorityMultiSelectorContext = createContext<PriorityMultiSelectorContextProps>({});

const usePriorityMultiSelectorContext = (): PriorityMultiSelectorContextProps =>
  useContext(PriorityMultiSelectorContext);
