import { type ReactNode } from "react";
import { Drawer } from "vaul";
import { cn } from "@repo/lib";
import { Button } from "@/components/button";

export interface DrawerProps {
  dismissible?: boolean;
  open: boolean;
  children: ReactNode;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}

export interface DrawerTriggerProps {
  children: ReactNode;
  asChild?: boolean;
}

export interface DrawerHeaderProps {
  children: ReactNode;
}

export interface DrawerTitleProps {
  children: ReactNode;
  className?: string;
}

export interface DrawerDescriptionProps {
  children: ReactNode;
}

export interface DrawerFooterProps {
  children: ReactNode;
}

export interface DrawerContentProps {
  children: ReactNode;
  className?: string;
}

export interface DrawerCloseProps {
  children: string;
  onClose: () => void;
  secondary?: boolean;
}

interface DrawerBodyProps {
  children: ReactNode;
}

export const DrawerTrigger = ({ children, asChild }: DrawerTriggerProps): JSX.Element => {
  return <Drawer.Trigger asChild={asChild}>{children}</Drawer.Trigger>;
};

export const DrawerRoot = ({
  dismissible = true,
  open,
  setOpen,
  onClose,
  children,
}: DrawerProps): JSX.Element => {
  const handleOpenChange = (isOpen: boolean): void => {
    setOpen(isOpen);
  };

  return (
    <Drawer.Root
      dismissible={dismissible}
      open={open}
      onOpenChange={handleOpenChange}
      onClose={onClose}
    >
      {children}
    </Drawer.Root>
  );
};

export const DrawerHeader = ({ children }: DrawerHeaderProps): JSX.Element => {
  return <div>{children}</div>;
};

export const DrawerFooter = ({ children }: DrawerFooterProps): JSX.Element => {
  return (
    <div className="flex justify-center flex-col items-center gap-4">{children}</div>
  );
};

export const DrawerBody = ({ children }: DrawerBodyProps): JSX.Element => {
  return (
    <div className="text-md overflow-x-hidden text-neutral-500 font-normal max-h-[55vh] overflow-auto">
      {children}
    </div>
  );
};

export const DrawerTitle = ({ children, className }: DrawerTitleProps): JSX.Element => {
  return (
    <Drawer.Title className={cn("text-lg font-medium text-neutral-700 ", className)}>
      {children}
    </Drawer.Title>
  );
};

export const DrawerDescription = ({ children }: DrawerDescriptionProps): JSX.Element => {
  return (
    <Drawer.Description className="text-md text-gray-600">{children}</Drawer.Description>
  );
};

export const DrawerClose = ({
  children,
  onClose,
  secondary,
}: DrawerCloseProps): JSX.Element => {
  return (
    <Drawer.Close asChild>
      <Button
        secondary={secondary}
        fullWidth
        className="text-gray-500 bg-transparent w-full hover:bg-gray-100 px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300"
        onClick={onClose}
      >
        {children}
      </Button>
    </Drawer.Close>
  );
};

export const DrawerContent = ({
  children,
  className,
}: DrawerContentProps): JSX.Element => {
  return (
    <Drawer.Portal>
      <Drawer.Overlay
        data-testid="drawer-overlay"
        className="fixed z-50 inset-0 bg-black/40"
      />
      <Drawer.Content
        className={cn(
          "z-[51] bg-white flex flex-col fixed bottom-base bottom-0 left-0 right-0 max-h-[95%] rounded-t-[10px] ",
          className,
        )}
      >
        <div className="p-4 pt-0 rounded-t-[10px]">
          <div className="z-[100] pt-4 fixed left-0 top-0 bg-white rounded-t-[10px] w-full">
            <div className="mx-auto w-12 h-1.5 rounded-full bg-neutral-300" />
          </div>
          <div className="max-w-md w-full mx-auto pt-12 flex flex-col gap-2">
            {children}
          </div>
        </div>
      </Drawer.Content>
    </Drawer.Portal>
  );
};
