import { ArrowLeft, ArrowRight } from "@repo/icons";
import { cn } from "@repo/lib";
import { useSearch } from "@tanstack/react-router";

interface TimePeriodSelectorProps {
  monthsList: string[];
  onSelectedMonthChange: (newMonth: string) => void;
}

export const TimePeriodSelector = ({
  monthsList,
  onSelectedMonthChange,
}: TimePeriodSelectorProps): JSX.Element => {
  const activeClass = "bg-secondary-400";

  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const selectedMonth: string = (searchParams.selectedMonth as string)
    ? (searchParams.selectedMonth as string)
    : monthsList[0];

  const currentMonthIndex = monthsList.findIndex((month) => month === selectedMonth);

  const handlePreviousMonth = (): void => {
    if (currentMonthIndex > 0) {
      onSelectedMonthChange(monthsList[currentMonthIndex - 1]);
    }
  };

  const handleNextMonth = (): void => {
    if (currentMonthIndex < monthsList.length - 1) {
      onSelectedMonthChange(monthsList[currentMonthIndex + 1]);
    }
  };

  const hasPreviousMonth = currentMonthIndex !== 0;
  const hasNextMonth = currentMonthIndex !== monthsList.length - 1;

  return (
    <div
      className="bg-neutral-100 flex w-full justify-between h-14 items-center px-3"
      data-testid="time-period-selector"
    >
      <button
        type="button"
        onClick={handlePreviousMonth}
        className={cn(
          "flex justify-center items-center w-5 h-5 rounded-full fill-white",
          hasPreviousMonth ? activeClass : "bg-neutral-200",
        )}
        data-testid="previous-month-button"
      >
        <ArrowLeft size={16} />
      </button>
      <span className="text-md font-medium">{selectedMonth}</span>
      <button
        type="button"
        onClick={handleNextMonth}
        className={cn(
          "flex justify-center items-center w-5 h-5 rounded-full fill-white",
          hasNextMonth ? activeClass : "bg-neutral-200",
        )}
        data-testid="next-month-button"
      >
        <ArrowRight size={16} />
      </button>
    </div>
  );
};
