/**
 * Retorna informações do usuário
 *
 * @param member - Dados do usuário.
 * @param specialties - Lista das especialidades do usuário.
 * @returns - Retorna um objeto com informações do usuário.
 */
export const calculatePercentage = (part: number, total: number): number => {
  if (total === 0) return 0;
  return Math.round((part / total) * 100);
};
