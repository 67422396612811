import {
  type ReactNode,
  Children,
  isValidElement,
  type ReactElement,
  useContext,
} from "react";
import {
  PieChart,
  ResponsiveContainer,
  Pie,
  Cell,
  Legend,
  type PieProps,
} from "recharts";
import { type Payload } from "recharts/types/component/DefaultLegendContent";
import { ChartContext } from "@/lib/context/chart-context";
import { ChartLegendItem } from "@/components/charts/chart-legend-item";

const DEFAULT_PIE_COLORS = ["#005675", "#72ABBC", "#ABD3DD", "#E1F0F5"];

interface ChartPieContainerProps extends PieProps {
  dataLabelField: string;
}

export const ChartPieContainer = ({
  width,
  height,
  dataLabelField,
  children,
  ...props
}: ChartPieContainerProps): JSX.Element => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error("ChartPieContainer must be used within a ChartProvider");
  }

  const { data } = context;
  const { ref: _ref, ...propsWithoutRef } = props;

  return (
    <ResponsiveContainer width={width ?? "100%"} height={height ?? "100%"} minWidth={100}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={44}
          outerRadius={65}
          animationBegin={0}
          {...propsWithoutRef}
        >
          {Children.map(children, (child, index) => {
            if (isValidElement(child) && child.type === ChartPieItem) {
              const { itemLabel, fill } = child.props as ChartPieItemProps;
              return ChartPieItem({
                fill: fill ?? DEFAULT_PIE_COLORS[index],
                itemLabel,
                dataLabelField,
              });
            }
            return null;
          })}
        </Pie>
        {Children.map(children, (child) => {
          if (isValidElement(child) && child.type === ChartPieLegend) {
            const { variations } = child.props as ChartPieLegendProps;
            return (
              <Legend
                wrapperStyle={{ paddingTop: "24px" }}
                content={<ChartPieLegend chartsItem={children} variations={variations} />}
              />
            );
          }
          return null;
        })}
      </PieChart>
    </ResponsiveContainer>
  );
};

interface ChartPieItemProps {
  fill?: string;
  itemLabel: string;
  dataLabelField?: string;
}

export const ChartPieItem = ({
  fill,
  itemLabel,
  dataLabelField,
}: ChartPieItemProps): JSX.Element | null => {
  const chartContext = useContext(ChartContext);

  if (!chartContext) {
    throw new Error("ChartPieItem must be used within a ChartProvider");
  }

  if (!dataLabelField) return null;

  const { data } = chartContext;

  const itemIndex = data.findIndex((item) => item[dataLabelField] === itemLabel);

  if (itemIndex === -1) {
    return null;
  }

  return <Cell key={`cell-${itemLabel} : null}`} fill={fill} className="outline-none" />;
};

interface ChartLegendItemProps {
  dataKey: string | number;
  fill?: string;
  itemLabel?: string;
}

interface ChartPieLegendProps {
  payload?: Payload[];
  chartsItem?: ReactNode;
  variations?: Record<string, number>;
}

export const ChartPieLegend = ({
  payload,
  chartsItem,
  variations,
}: ChartPieLegendProps): JSX.Element => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error("ChartPieLegend must be used within a ChartProvider");
  }

  const getPieItem = (index: number): ChartLegendItemProps | null => {
    const element = Array.isArray(chartsItem)
      ? (chartsItem[index] as ReactElement<ChartLegendItemProps>)
      : undefined;
    if (isValidElement(element)) {
      return element.props;
    }

    return null;
  };

  return (
    <div className="flex flex-wrap gap-2 w-full">
      {payload?.map((entry, index) => {
        const item = entry.payload as { color?: string; value?: unknown };
        const fill = entry.color ?? DEFAULT_PIE_COLORS[index];
        const pieItem = getPieItem(index);
        const displayText = pieItem?.itemLabel ?? String(entry.value);
        const variation = variations?.[displayText];

        return (
          <ChartLegendItem
            key={`legend-item-${String(index)}- ${displayText}`}
            fill={fill}
            value={String(item.value)}
            displayText={displayText}
            index={index}
            variation={variation}
          />
        );
      })}
    </div>
  );
};
