import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { UserTrackableLinkReportDetailSummary } from "@/components/user-trackable-links/report/user-trackable-link-report-detail-summary";
import { UserTrackableLinkReportDetailList } from "@/components/user-trackable-links/report/user-trackable-link-report-detail-list";

const UserTrackableLinkReportPageQuery = graphql(`
  query UserTrackableLinkReportPageQuery($userTrackableLinkId: Int!) {
    userTrackableLink: usuariosLinksRastreamentos_by_pk(
      codUsuarioLinkRastreamento: $userTrackableLinkId
    ) {
      codLinkRastreamento
      dataCadastro
      ...UserTrackableLinkReportDetailSummaryFragment
    }
  }
`);

export const UserTrackableLinkReport = (): JSX.Element => {
  const { userTrackableLinkId } = Route.useParams();
  const queryResult = useGraphQL(UserTrackableLinkReportPageQuery, {
    userTrackableLinkId: parseInt(userTrackableLinkId),
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Relatório da campanha" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <div className="flex flex-col gap-4">
            {data?.userTrackableLink ? (
              <>
                <UserTrackableLinkReportDetailSummary
                  userTrackableLink={data.userTrackableLink}
                />
                <div className="flex flex-col gap-2">
                  <UserTrackableLinkReportDetailList
                    codLinkRastreamento={data.userTrackableLink.codLinkRastreamento}
                    dataCadastro={data.userTrackableLink.dataCadastro}
                  />
                </div>
              </>
            ) : null}
          </div>
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/livance-via/user-trackable-links/$userTrackableLinkId/report/",
)({
  component: UserTrackableLinkReport,
});
