import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type SchedulingConversionRateSessionFragmentQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { CustomerContactsTotalNumber } from "@/components/customer-contact-overview/customer-contacts-total-number";
import { trackEvent } from "@/lib/tracking";

export const SchedulingConversionRateSessionFragmentQueryFragment = graphql(
  /* GraphQL */ `
    fragment SchedulingConversionRateSessionFragmentQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      percentCustomerContactsAppointmentsScheduled
      totalCustomerContactsNewAppointmentMainReason
      totalCustomerContactsAppointmentsScheduled
      period
    }
  `,
);

interface SchedulingConversionRateProps {
  data: FragmentType<typeof SchedulingConversionRateSessionFragmentQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const SchedulingConversionRate = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: SchedulingConversionRateProps): JSX.Element | null => {
  const fragmentData = useFragment(
    SchedulingConversionRateSessionFragmentQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showSchedulingConversionRateDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Conversão de agendamento",
      });

      void router.navigate({ search });
    } else if (searchParams.showSchedulingConversionRateDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div className="my-8 flex flex-col gap-8">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        Conversão de agendamento
      </CustomerContactSectionHeader>
      {fragmentData.length === 0 || !currentMonthData ? (
        <CustomerContactsOverviewEmptyState />
      ) : (
        <>
          <CustomerContactsTotalNumber
            total={Number(currentMonthData.percentCustomerContactsAppointmentsScheduled)}
            isPercent
          />
          <SchedulingConversionRateChart
            data={
              fragmentData as SchedulingConversionRateSessionFragmentQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showSchedulingConversionRateDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Conversão de agendamento"
      >
        <p className="text-md">
          Agendamentos realizados a partir de contatos que selecionaram a opção agendar
          consulta no inicio do atendimento.
        </p>
      </CustomerContactDrawer>
    </div>
  );
};

interface SchedulingConversionRateChartProps {
  data: SchedulingConversionRateSessionFragmentQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const SchedulingConversionRateChart = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: SchedulingConversionRateChartProps): JSX.Element => {
  const chartData = data.map((x) => {
    return {
      "Interessados em agendar": x.totalCustomerContactsNewAppointmentMainReason,
      Agendaram: x.totalCustomerContactsAppointmentsScheduled,
      mes: x.period,
    };
  });

  return (
    <div data-testid="scheduling-conversion-rate-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={chartData}
      >
        <ChartBarContainer height={299} XAxisKey="mes">
          <ChartBarItem dataKey="Agendaram" fill="#F5AE45" disabledFill="#D5DADF" />
          <ChartBarItem
            dataKey="Interessados em agendar"
            fill="#FFD094"
            disabledFill="#EDF0F2"
          />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
