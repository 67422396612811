import { CpsBadge, type CpsBadgeProps } from "corpus";

interface ChartLegendItemProps {
  fill?: string;
  value: string;
  displayText: string;
  index: number;
  variation?: number;
}

export const ChartLegendItem = ({
  fill,
  value,
  displayText,
  index,
  variation,
}: ChartLegendItemProps): JSX.Element => {
  return (
    <div
      key={`legend-item-${String(index)}`}
      className="flex items-center gap-2 bg-neutral-50 rounded-30 border border-neutral-100 w-full flex-[1_0_40%] p-3 shrink-0"
      data-testid={`legend-item-${String(index)}`}
    >
      {fill ? (
        <div
          className="rounded-full w-3 h-3 flex-shrink-0"
          data-testid="chart-legend-color"
          style={{ backgroundColor: fill }}
        />
      ) : null}
      <div className="flex flex-col items-start">
        <div className="flex gap-2 items-center">
          <span
            className="color-neutral-600 text-sm font-medium"
            data-testid="chart-legend-value"
          >
            {value}
          </span>

          {variation ? (
            <CpsBadge
              className="px-1.5 py-0 text-[10px] font-medium h-[17px]"
              color={(variation > 0 ? "success" : "danger") as CpsBadgeProps["color"]}
            >
              {`${variation > 0 ? "+" : "-"} ${Math.abs(variation)}%`}
            </CpsBadge>
          ) : null}
        </div>
        <span className="color-neutral-500 text-xs" data-testid="chart-legend-text">
          {displayText}
        </span>
      </div>
    </div>
  );
};
