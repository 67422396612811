import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import { z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { useResetAtom } from "jotai/utils";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { CreatePaymentForm } from "@/components/create-payment-form";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { RecipientEmptyStateWrapper } from "@/components/recipient-empty-state";
import { CreatePaymentTooltipDrawer } from "@/components/create-payment-tooltip-drawer";
import { createPaymentFormAtom } from "@/lib/atoms/create-payment-atom";

const CreatePaymentPageQuery = graphql(/* GraphQL */ `
  query CreatePaymentPageQuery($appointmentId: Int!) {
    ...CreatePaymentFormFragment
  }
`);

export const CreatePaymentPage = (): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({ from: "/settings/payments/create/" });
  const { appointmentId, about } = searchParams;

  const queryResult = useGraphQL(CreatePaymentPageQuery, {
    appointmentId: appointmentId ?? 0,
  });
  const { data } = queryResult;

  const resetCreatePaymentForm = useResetAtom(createPaymentFormAtom);

  const onBackButtonClick = (): void => {
    resetCreatePaymentForm();
    router.history.back();
  };

  const setOpenTooltipDrawer = (open: boolean): void => {
    if (open) {
      void router.navigate({
        search: {
          ...searchParams,
          about: true,
        },
      });
    } else if (about) {
      router.history.back();
    }
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} onClick={onBackButtonClick} />
        <HeaderTitle title="Criar pagamento" align="center" />
        {!appointmentId ? (
          <HeaderButton
            icon={HelpCircle}
            onClick={() => setOpenTooltipDrawer(true)}
            align="end"
            data-testid="create-payment-help-button"
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment --- não da pra passar null dentro do header
          <></>
        )}
      </HeaderRoot>
      <Page>
        <RecipientEmptyStateWrapper>
          <AsyncDataWrapper {...queryResult}>
            {data ? <CreatePaymentForm data={data} /> : null}
          </AsyncDataWrapper>
        </RecipientEmptyStateWrapper>
        {!appointmentId ? (
          <CreatePaymentTooltipDrawer
            open={Boolean(about)}
            setOpen={setOpenTooltipDrawer}
          />
        ) : null}
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/create/")({
  component: CreatePaymentPage,
  validateSearch: z.object({
    appointmentId: z.number().optional(),
    origin: z.string().optional(),
    about: z.boolean().optional(),
  }),
});
