import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { z } from "zod";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AppointmentReceivingOptionsForm } from "@/components/appointment-receiving-options-form";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { PendingComponent } from "@/components/pending-component";

const AppointmentReceivingOptionsCreatePageQuery = graphql(/* GraphQL */ `
  query AppointmentReceivingOptionsCreatePageQuery($codUsuarioFormaRecebimento: Int!) {
    ...AppointmentReceivingOptionsFormQueryFragment
  }
`);

const appointmentReceivingOptionSchema = z.object({
  action: z.string().optional(),
});

export const AppointmentReceivingOptionsCreatePage = (): JSX.Element => {
  const queryResult = useGraphQL(AppointmentReceivingOptionsCreatePageQuery, {
    codUsuarioFormaRecebimento: -1,
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo convênio" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <AppointmentReceivingOptionsForm data={data} /> : <FullPageSpinner />}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/schedule-options/appointment-receiving-options/create",
)({
  component: AppointmentReceivingOptionsCreatePage,
  pendingComponent: () => <PendingComponent title="Novo convênio" />,
  validateSearch: appointmentReceivingOptionSchema,
});
