import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Bell } from "@repo/icons";
import { ListRoot } from "@/components/list";
import { FollowUpAppointmentReminderListItem } from "@/components/follow-up-appointment-reminder-list-item";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
  EmptyStateDescription,
} from "@/components/empty-state";

const FollowUpAppointmentReminderListQuery = graphql(/* GraphQL */ `
  fragment FollowUpAppointmentReminderListQuery on query_root {
    lembretes(
      where: {
        codTipoLembrete: { _eq: $codTipoLembreteRetorno }
        ativo: { _eq: true }
        dataEnviado: { _is_null: true }
        dataParaEnvio: { _gte: $hoje }
      }
      order_by: { dataParaEnvio: asc }
    ) {
      codLembrete
      ...FollowUpAppointmentReminderListItemQuery
    }
  }
`);

interface FollowUpAppointmentReminderListProps {
  data: FragmentType<typeof FollowUpAppointmentReminderListQuery>;
}

export const FollowUpAppointmentReminderList = ({
  data,
}: FollowUpAppointmentReminderListProps): JSX.Element => {
  const queryData = useFragment(FollowUpAppointmentReminderListQuery, data);

  return (
    <ListRoot>
      {queryData.lembretes.length ? (
        queryData.lembretes.map((lembrete) => (
          <FollowUpAppointmentReminderListItem
            key={lembrete.codLembrete}
            data={lembrete}
          />
        ))
      ) : (
        <EmptyStateRoot>
          <EmptyStateIcon icon={Bell} fill="primary" />
          <EmptyStateTitle>Você ainda não tem lembretes programados</EmptyStateTitle>
          <EmptyStateDescription>
            Crie um novo lembrete para os pacientes atendidos no dia na sala de espera
            virtual
          </EmptyStateDescription>
        </EmptyStateRoot>
      )}
    </ListRoot>
  );
};
