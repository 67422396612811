import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { formatIgnoringTimezone } from "@repo/lib";
import { ItemContent, Item, ItemMainContent } from "@/components/list";

export const UserTrackableLinkListItemQueryFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinkListItemQueryFragment on usuariosLinksRastreamentos {
    nome
    descricao
    dataCadastro
    codUsuarioLinkRastreamento
  }
`);

export const UserTrackableLinksListItem = (props: {
  userTrackableLinkItem: FragmentType<typeof UserTrackableLinkListItemQueryFragment>;
}): JSX.Element => {
  const trackableLink = useFragment(
    UserTrackableLinkListItemQueryFragment,
    props.userTrackableLinkItem,
  );

  const dataCadastro = formatIgnoringTimezone(
    new Date(trackableLink.dataCadastro),
    "dd/MM/yyyy",
  );

  return (
    <Item
      linkProps={{
        to: "/settings/livance-via/user-trackable-links/$userTrackableLinkId",
        params: { userTrackableLinkId: trackableLink.codUsuarioLinkRastreamento },
      }}
    >
      <ItemContent>
        <p className="text-sm line-clamp-1">{dataCadastro}</p>
        <ItemMainContent>{trackableLink.nome}</ItemMainContent>
        <p className="text-sm line-clamp-2">{trackableLink.descricao}</p>
      </ItemContent>
    </Item>
  );
};
