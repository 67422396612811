import { z } from "zod";

export const CreatePaymentFormSchema = z
  .object({
    patientName: z.string().min(3, "Nome do paciente é obrigatório"),
    phone: z
      .string({ required_error: "Você precisa informar um número de telefone" })
      .min(1, { message: "Você precisa informar um número de telefone" })
      .refine(
        (value) => /^\(\d{2}\) \d{4,5}-\d{4}$/.test(value),
        "Número de telefone inválido.",
      ),
    email: z.string().email("E-mail inválido"),
    sendPaymentAutomatically: z.boolean(),
    value: z
      .string({ required_error: "O valor é obrigatório" })
      .min(1, { message: "O valor é obrigatório" })
      .refine(
        (value) => {
          if (!value) return true;
          const numericValue = Number.parseFloat(value.replace(",", "."));
          return !Number.isNaN(numericValue) && numericValue > 0;
        },
        { message: "O valor do pagamento deve ser maior que R$ 0,00" },
      ),
    serviceName: z
      .string({ required_error: "Você precisa informar o nome do serviço" })
      .min(3, "Nome do serviço é obrigatório"),
    paymentMethods: z
      .array(
        z.object({
          id: z.number(),
          name: z.string(),
          description: z.string(),
        }),
      )
      .min(1, "Selecione pelo menos uma forma de pagamento"),
    installments: z.number().int().min(1),
  })
  .transform((formData) => {
    return {
      ...formData,
      installments:
        formData.paymentMethods.length === 1 &&
        formData.paymentMethods.some((method) => method.name === "pix")
          ? 1
          : formData.installments,
    };
  });

export type CreatePaymentFormValues = z.infer<typeof CreatePaymentFormSchema>;
