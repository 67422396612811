import { useEffect } from "react";
import { CpsRadioButtonGroup, CpsRadioButtonV2 } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  ActiveAllocationDetailsQueryDocument,
  type CreateExtendTimeMutationMutation,
} from "@repo/graphql-types/graphql";
import { ExclamationCircle } from "@repo/icons";
import { useNavigate, useRouter, useSearch } from "@tanstack/react-router";
import { TimeSlotSuggestionType } from "@repo/lib";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { getTotalMinutes } from "@/lib/time";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
  EmptyStateDescription,
} from "@/components/empty-state";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { trackEvent } from "@/lib/tracking";

export const ExtendTimeFormFragment = graphql(/* GraphQL */ `
  fragment ExtendTimeFormFragment on query_root {
    LivanceApiBuscaHorariosParaEstender(arg1: { codAgendamento: $codAgendamento }) {
      dataHora
      dataHoraFim
      duracao
      tipoHorarioSugestao
    }
    activeAllocation: AgendamentosAlocacoesAtivas_by_pk(codAgendamento: $codAgendamento) {
      codAgendamento
      Agendamento {
        codUsuario
        codUnidade
        UsuarioCompromisso {
          duracao
        }
      }
    }
  }
`);

const CreateExtendTimeMutation = graphql(`
  mutation CreateExtendTimeMutation($input: EstenderAgendamentoInput!) {
    estenderAgendamento(input: $input) {
      errors {
        ... on ValidationError {
          __typename
          message
        }
      }
      agendamentoApiOutput {
        codAgendamento
      }
    }
  }
`);

const ExtendTimeFormSchema = z.object({
  extendedTimeIndex: z.string({
    required_error: "É necessário escolher o tempo de extensão",
  }),
});

interface ExtendTimeFormProps {
  queryData: FragmentType<typeof ExtendTimeFormFragment>;
}

interface ExtendTimeSearchParams {
  elapsedTime: number;
  isLate: boolean;
}

export const ExtendTimeForm = ({ queryData }: ExtendTimeFormProps): JSX.Element => {
  const dataFragment = useFragment(ExtendTimeFormFragment, queryData);
  const router = useRouter();
  const { elapsedTime, isLate }: ExtendTimeSearchParams = useSearch({
    strict: false,
  });

  const invalidateActiveAllocationDetailsQuery = useInvalidateQuery(
    ActiveAllocationDetailsQueryDocument,
  );

  const onCloseErrorDrawer = (): void => {
    router.history.go(-2);
  };
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(CreateExtendTimeMutation, {
    retry: 2,
    onCloseErrorDrawer,
  });
  const navigate = useNavigate();

  type FormFields = z.infer<typeof ExtendTimeFormSchema>;
  const form = useForm<FormFields>({
    resolver: zodResolver(ExtendTimeFormSchema),
  });

  const activeAllocation = dataFragment.activeAllocation;

  useEffect(() => {
    trackEvent("Extensão Solicitada", {
      codUsuario: activeAllocation?.Agendamento.codUsuario,
      codAgendamento: activeAllocation?.codAgendamento,
      codUnidade: activeAllocation?.Agendamento.codUnidade,
      duracaoTeorica: activeAllocation?.Agendamento.UsuarioCompromisso?.duracao,
      extensoresApresentados: dataFragment.LivanceApiBuscaHorariosParaEstender?.map((h) =>
        getTotalMinutes(h?.duracao ?? ""),
      ),
      tempoAlocado: elapsedTime,
      atrasado: isLate,
    });
  }, [
    activeAllocation,
    elapsedTime,
    isLate,
    dataFragment.LivanceApiBuscaHorariosParaEstender,
  ]);

  if (!dataFragment.LivanceApiBuscaHorariosParaEstender?.length) {
    return (
      <EmptyStateRoot>
        <EmptyStateIcon fill="primary" icon={ExclamationCircle} />
        <EmptyStateTitle>
          Não há disponibilidade para estender esse atendimento.
        </EmptyStateTitle>
        <EmptyStateDescription>
          Por favor, devolva a sala no horário programado para não prejudicar outros
          profissionais e evitar a cobrança adicional por atraso.
        </EmptyStateDescription>
      </EmptyStateRoot>
    );
  }

  const data = dataFragment.LivanceApiBuscaHorariosParaEstender;

  const navigateWithSearch = (
    action: "extend-time-success" | "error",
    codAgendamento?: number,
  ): void => {
    void navigate({
      to: "/",
      search: {
        action,
        codAgendamento,
      },
      replace: true,
    });
  };

  const handleSubmit = async (selectedValue: FormFields): Promise<void> => {
    const onError = (_: Error): void => {
      // Error handler já cuida de mostrar o erro
    };

    const onSuccess = (response: CreateExtendTimeMutationMutation): void => {
      trackEvent("Extensão Confirmada", {
        codUsuario: activeAllocation?.Agendamento.codUsuario,
        codAgendamento: response.estenderAgendamento.agendamentoApiOutput?.codAgendamento,
        codAgendamentoPrincipal: activeAllocation?.codAgendamento,
        extensorSelecionado: selectedValue.extendedTimeIndex,
      });

      invalidateActiveAllocationDetailsQuery();

      navigateWithSearch(
        "extend-time-success",
        response.estenderAgendamento.agendamentoApiOutput?.codAgendamento,
      );
    };

    if (!activeAllocation) {
      onError(new Error("Não foi possível estender o agendamento"));
      return;
    }

    await mutateAsync(
      {
        input: {
          codAgendamentoAlocacaoAtiva: activeAllocation.codAgendamento,
          duracao: selectedValue.extendedTimeIndex,
        },
      },
      { onSuccess, onError },
    );
  };

  const selectedDuration = form.watch("extendedTimeIndex");

  return (
    <div className="pb-16">
      <FormRoot {...form}>
        <FormHandlerSubmit className="w-full" handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="extendedTimeIndex"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <>
                    <label
                      className="text-sm font-medium text-neutral-600"
                      htmlFor="extended-time-index"
                    >
                      A partir deste momento, quantos minutos deseja adicionar?
                    </label>
                    {data.length > 0 ? (
                      <CpsRadioButtonGroup onChange={field.onChange}>
                        {data
                          .map((element) => {
                            const totalMinutes = getTotalMinutes(element?.duracao ?? "");
                            const label =
                              element?.tipoHorarioSugestao ===
                              TimeSlotSuggestionType.ExtendedTimeUntilNextAppointment
                                ? "Estender até o próximo agendamento"
                                : `+ ${totalMinutes} Minutos`;

                            if (!element?.duracao) {
                              return null;
                            }

                            return (
                              <CpsRadioButtonV2
                                key={`extended-time-${totalMinutes}`}
                                value={element.duracao}
                                label={label}
                                id={`extended-time-${totalMinutes}`}
                              />
                            );
                          })
                          .filter((x) => x !== null)}
                      </CpsRadioButtonGroup>
                    ) : null}
                  </>
                </FormControl>
              </FormItem>
            )}
          />
          <FormSubmitButton
            disabled={!selectedDuration}
            className="fixed w-full bottom-0 left-0 p-4"
          >
            Confirmar
          </FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
    </div>
  );
};
