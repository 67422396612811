import { ChartLegendItem } from "@/components/charts/chart-legend-item";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";

interface SingleNumberChartProps {
  chartTitle: string;
  totalNumber: number;
  displayText: string;
  legendNumber: string;
  legendText: string;
  legendIndex: number;
  onOpenChange: (open: boolean) => void;
}

export const SingleNumberChart = ({
  onOpenChange,
  chartTitle,
  totalNumber,
  displayText,
  legendNumber,
  legendText,
  legendIndex,
}: SingleNumberChartProps): JSX.Element => {
  return (
    <div className="my-8 flex flex-col gap-3">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        {chartTitle}
      </CustomerContactSectionHeader>
      <SingleNumberChartHeader totalNumber={totalNumber} displayText={displayText} />
      <ChartLegendItem
        value={legendNumber}
        displayText={legendText}
        index={legendIndex}
      />
    </div>
  );
};

interface SingleNumberChartHeaderProps {
  totalNumber: number;
  displayText: string;
}

export const SingleNumberChartHeader = ({
  totalNumber,
  displayText,
}: SingleNumberChartHeaderProps): JSX.Element => {
  return (
    <div className="text-center flex flex-col mt-3">
      <div className="flex flex-row justify-center items-center gap-1">
        <span className="font-semibold text-2xl">{totalNumber}</span>
      </div>
      <p className="text-sm text-neutral-500">{displayText}</p>
    </div>
  );
};
