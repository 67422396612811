import { graphql } from "@repo/graphql-types/gql";
import { Trash } from "@repo/icons";
import { logError } from "@repo/lib";
import { useRouter, useSearch } from "@tanstack/react-router";
import { FollowUpAppointmentReminderPageQueryDocument } from "@repo/graphql-types/graphql";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer.tsx";

const DeleteAppointmentReminder = graphql(/* GraphQL */ `
  mutation DeleteAppointmentReminder($codLembrete: Int!) {
    update_lembretes_by_pk(
      pk_columns: { codLembrete: $codLembrete }
      _set: { ativo: false }
    ) {
      ativo
      codLembrete
    }
  }
`);

interface DeleteFollowUpAppointmentReminderDrawerParams {
  codLembrete?: number;
}

export const DeleteFollowUpAppointmentReminderDrawer = (): JSX.Element => {
  const router = useRouter();
  const onCloseErrorDrawer = (): void => {
    router.history.go(-2);
  };

  const params: DeleteFollowUpAppointmentReminderDrawerParams = useSearch({
    strict: false,
  });

  const { mutateAsync, isPending } = useGraphQLMutationWithErrorHandler(
    DeleteAppointmentReminder,
    { onCloseErrorDrawer },
  );

  const invalidateWaitingRoomQuery = useInvalidateQuery(
    FollowUpAppointmentReminderPageQueryDocument,
  );

  const openDrawer = Boolean(params.codLembrete);
  const setShowDrawer = (open: boolean): void => {
    if (!open) {
      router.history.back();
    }
  };

  const handleAppointmentCancel = async (): Promise<void> => {
    const onSuccess = (): void => {
      invalidateWaitingRoomQuery();
      setShowDrawer(false);
    };

    const onError = (error: Error): void => {
      logError(error);
    };

    await mutateAsync({ codLembrete: params.codLembrete ?? 0 }, { onSuccess, onError });
  };

  return (
    <MessageDrawerRoot
      icon={Trash}
      setOpen={setShowDrawer}
      open={openDrawer}
      variant="secondary"
    >
      <MessageDrawerTitle>Apagar lembrete</MessageDrawerTitle>
      <MessageDrawerBody>
        O paciente não será mais lembrado no dia programado.
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton
          onClick={() => void handleAppointmentCancel()}
          loading={isPending}
        >
          Apagar lembrete
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
