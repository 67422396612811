import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { PendingComponent } from "@/components/pending-component";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { UserTrackableLinksList } from "@/components/user-trackable-links/user-trackable-links-list";

const UserTrackableLinksQuery = graphql(/* GraphQL */ `
  query UserTrackableLinksQuery {
    ...UserTrackableLinksQueryFragment
  }
`);

export const UserTrackableLinksPage = (): JSX.Element => {
  const queryResult = useGraphQL(UserTrackableLinksQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Campanhas" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <UserTrackableLinksList userTrackableLinkList={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/livance-via/user-trackable-links/")({
  component: UserTrackableLinksPage,
  pendingComponent: () => <PendingComponent title="Campanhas" />,
});
