import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { CreditCardForm } from "@/components/credit-card-form";

const createCardOptionSchema = z.object({
  action: z.string().optional(),
});

export const CardCreatePage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Cadastrar cartão" align="center" />
      </HeaderRoot>
      <Page>
        <CreditCardForm />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/card/create")({
  component: CardCreatePage,
  validateSearch: createCardOptionSchema,
});
