export enum ContactReason {
  NewAppointment = 1,
  ExistingAppointment = 2,
  PostAppointment = 3,
  Others = 4,
}

//TODO: Passar para a tabela de categoria_atendimento
export const contactReasonMap: Record<ContactReason, string> = {
  [ContactReason.NewAppointment]: "Novos agendamentos",
  [ContactReason.ExistingAppointment]: "Tratar de consulta marcada",
  [ContactReason.PostAppointment]: "Dúvidas pós-consulta",
  [ContactReason.Others]: "Informações diversas",
};
