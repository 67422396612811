import { CpsBadge } from "corpus";

interface UserTrackableLinkReportDetailListItemProps {
  name: string;
  value: string;
  percentage?: number;
}

export const UserTrackableLinkReportDetailListItem = ({
  name,
  value,
  percentage,
}: UserTrackableLinkReportDetailListItemProps): JSX.Element => {
  return (
    <div className="flex justify-between items-center bg-neutral-50 rounded-30 border border-neutral-100 w-full flex-[1_0_40%] p-3 shrink-0">
      <p className="text-sm text-neutral-500">{name}</p>
      <div className="flex flex-row gap-2">
        <p className="font-medium text-neutral-600"> {value} </p>
        {percentage ? (
          <CpsBadge color="success">{`${percentage.toString()}%`}</CpsBadge>
        ) : null}
      </div>
    </div>
  );
};
