import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { useSuspenseGraphQL } from "@/hooks/use-graphql.ts";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import { PendingComponent } from "@/components/pending-component.tsx";
import { SecretaryDetailsProfessionalInfosForm } from "@/components/secretary-details/professional-infos-form.tsx";

export const GetSecretaryProfessionalInfosQuery = graphql(/* GraphQL */ `
  query GetSecretaryProfessionalInfosQuery {
    ...SecretaryDetailsProfessionalInfosFormFragment
  }
`);

const ProfessionalInfosPage = (): React.ReactNode => {
  const { data } = useSuspenseGraphQL(GetSecretaryProfessionalInfosQuery, {});
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="" align="center" />
      </HeaderRoot>
      <Page>
        <SecretaryDetailsProfessionalInfosForm data={data} />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/livance-via/secretary-details/professional-infos",
)({
  component: ProfessionalInfosPage,
  pendingComponent: () => <PendingComponent title="" />,
});
