import { useFragment, type FragmentType } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";

export const UserTrackableLinksDetailsDescriptionFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinksDetailsDescriptionFragment on usuariosLinksRastreamentos {
    nome
    descricao
  }
`);

interface UserTrackableLinksDetailsDescriptionProps {
  data: FragmentType<typeof UserTrackableLinksDetailsDescriptionFragment>;
}

export const UserTrackableLinksDetailsDescription = ({
  data,
}: UserTrackableLinksDetailsDescriptionProps): JSX.Element => {
  const fragmentData = useFragment(UserTrackableLinksDetailsDescriptionFragment, data);

  const { nome, descricao } = fragmentData;

  return (
    <PageTitleRoot>
      <PageTitleMainContent>{nome}</PageTitleMainContent>
      {descricao ? <PageTitleDescription>{descricao}</PageTitleDescription> : null}
    </PageTitleRoot>
  );
};
