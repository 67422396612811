export const SPECIALTY_IDS = {
  psychoanalysis: 57,
  psychology: 58,
  psychopedagogy: 70,
} as const;

/**
 * Retorna informações do usuário
 *
 * @param member - Dados do usuário.
 * @param specialties - Lista das especialidades do usuário.
 * @returns - Retorna um objeto com informações do usuário.
 */
export const useMemberInfo = (
  member: { dataValidacao?: string | null }[],
  specialties: { codEspecialidade: number }[],
): { memberRegistrationDate: string; memberIsPsico: boolean } => {
  const memberRegistrationDate = String(member[0]?.dataValidacao ?? null);

  const memberIsPsico = specialties.some((especialidade) =>
    (Object.values(SPECIALTY_IDS) as number[]).includes(especialidade.codEspecialidade),
  );

  return { memberRegistrationDate, memberIsPsico };
};
