"use client";

import { cn } from "@repo/lib";
import { useId, type ComponentPropsWithoutRef } from "react";
import { type ChartContextProps, ChartProvider } from "@/lib/context/chart-context";
import "@/styles/chart.css";

interface ChartRootProps
  extends Omit<ComponentPropsWithoutRef<"div">, "id">,
    Omit<ChartContextProps, "id"> {
  id?: string | number;
}

export const ChartRoot = ({
  selectedKey,
  onSelectedKeyChange,
  data,
  children,
  className,
  labels,
  id,
  ...props
}: ChartRootProps): JSX.Element | null => {
  const uniqueId = useId();
  const chartId = `chart-${id ?? uniqueId.replace(/:/g, "")}`;

  if (!children) {
    return null;
  }

  return (
    <ChartProvider
      selectedKey={selectedKey}
      onSelectedKeyChange={onSelectedKeyChange}
      data={data}
      id={chartId}
      labels={labels}
    >
      <div
        data-chart={chartId}
        className={cn(
          "flex justify-center [&_.recharts-cartesian-axis-tick_text]:fill-muted-foreground [&_.recharts-cartesian-grid_line[stroke='#ccc']]:stroke-border/50 [&_.recharts-curve.recharts-tooltip-cursor]:stroke-border [&_.recharts-dot[stroke='#fff']]:stroke-transparent [&_.recharts-layer]:outline-none [&_.recharts-polar-grid_[stroke='#ccc']]:stroke-border [&_.recharts-radial-bar-background-sector]:fill-muted [&_.recharts-rectangle.recharts-tooltip-cursor]:fill-muted [&_.recharts-reference-line_[stroke='#ccc']]:stroke-border [&_.recharts-sector[stroke='#fff']]:stroke-transparent [&_.recharts-sector]:outline-none [&_.recharts-surface]:outline-none",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </ChartProvider>
  );
};
