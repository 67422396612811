import {
  type PaymentMethodsListToSelectQueryFragmentFragment,
  type PaymentMethodsListToMultiSelectQueryFragmentFragment,
} from "@repo/graphql-types/graphql";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { HealthInsurance } from "@/enums/payment-method/health-insurance";
import { PaymentMethodType } from "@/enums/payment-method/payment-method-type";

export const mapPaymentMethodsToSelectDrawerItemsProps = (
  paymentMethods:
    | readonly PaymentMethodsListToSelectQueryFragmentFragment[]
    | readonly PaymentMethodsListToMultiSelectQueryFragmentFragment[],
): SelectDrawerItemProps<number>[] => {
  return paymentMethods
    .toSorted((a, b) =>
      a.TipoConvenio && b.TipoConvenio
        ? a.TipoConvenio.nome.localeCompare(b.TipoConvenio.nome)
        : 0,
    )
    .sort((a, b) => a.codTipoFormaRecebimento - b.codTipoFormaRecebimento)
    .map((paymentMethod) => {
      const value = paymentMethod.codUsuarioFormaRecebimento;
      let label = paymentMethod.TipoFormaRecebimento.nome;

      const isHealthInsurance =
        paymentMethod.codTipoFormaRecebimento ===
        Number(PaymentMethodType.HealthInsurance);

      if (!isHealthInsurance) {
        return {
          value,
          label,
        };
      }

      const isOtherHealthInsurance =
        paymentMethod.codTipoConvenio === HealthInsurance.Other;
      const healthInsuranceName = isOtherHealthInsurance
        ? paymentMethod.nomeConvenio
        : paymentMethod.TipoConvenio?.nome;

      label = `${label} ${healthInsuranceName}`;

      return {
        value,
        label,
      };
    });
};
