import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type TotalCustomerContactsQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactsTotalNumber } from "@/components/customer-contact-overview/customer-contacts-total-number";
import { trackEvent } from "@/lib/tracking";

export const TotalCustomerContactsQueryFragment = graphql(/* GraphQL */ `
  fragment TotalCustomerContactsQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
    totalCustomerContacts
    totalCustomerContactsVoice
    percentCustomerContactsVoice
    totalCustomerContactsChat
    percentCustomerContactsChat
    period
  }
`);

interface TotalCustomerContactsProps {
  data: FragmentType<typeof TotalCustomerContactsQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const TotalCustomerContacts = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: TotalCustomerContactsProps): JSX.Element => {
  const fragmentData = useFragment(TotalCustomerContactsQueryFragment, data);

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Total de contatos",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        Total de contatos
      </CustomerContactSectionHeader>
      {fragmentData.length === 0 || !currentMonthData ? (
        <CustomerContactsOverviewEmptyState />
      ) : (
        <>
          <CustomerContactsTotalNumber total={currentMonthData.totalCustomerContacts} />
          <TotalCustomerContactsChart
            totalCustomerContactsData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showCustomerContactsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Total de contatos"
      >
        <p>Total de contatos recebidos nos canais de atendimento, segmentados por:</p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-bold ">Telefone: </span>
            <span>Ligações recebidas e realizadas.</span>
          </li>
          <li className="mb-1 list-item">
            <span className="font-bold ">WhatsApp: </span>
            <span>Conversas abertas.</span>
          </li>
        </ul>
      </CustomerContactDrawer>
    </div>
  );
};

interface TotalCustomerContactsChartProps {
  totalCustomerContactsData: readonly TotalCustomerContactsQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const TotalCustomerContactsChart = ({
  totalCustomerContactsData,
  selectedMonth,
  onSelectedMonthChange,
}: TotalCustomerContactsChartProps): JSX.Element => {
  const data = totalCustomerContactsData.map(
    ({ totalCustomerContactsChat, totalCustomerContactsVoice, period }) => ({
      WhatsApp: totalCustomerContactsChat,
      Telefone: totalCustomerContactsVoice,
      mes: period,
    }),
  );

  const labels = totalCustomerContactsData.map(
    ({ percentCustomerContactsChat, percentCustomerContactsVoice }) => ({
      WhatsApp:
        percentCustomerContactsChat === 0 ? "" : `${percentCustomerContactsChat}%`,
      Telefone:
        percentCustomerContactsVoice === 0 ? "" : `${percentCustomerContactsVoice}%`,
    }),
  );

  return (
    <div data-testid="professional-customer-contact-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Telefone" fill="#72ABBC" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="WhatsApp" fill="#00658A" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
