import { Share, X } from "@repo/icons";
import { cn, iosOrAndroid, logError } from "@repo/lib";
import { CpsButton, CpsButtonText } from "corpus";
import { toBlob, toPng } from "html-to-image";
import { useDrag } from "@use-gesture/react";
import {
  Children,
  isValidElement,
  useState,
  type ReactNode,
  useContext,
  createContext,
  type ReactElement,
  useCallback,
  useMemo,
  type ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import InstaStories, { WithSeeMore } from "react-insta-stories";
import {
  type StoryProps,
  type Story,
  type ReactInstaStoriesProps,
  type Action,
} from "react-insta-stories/dist/interfaces";
import { Share as CapacitorShare } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Button } from "@/components/button";

export const StoriesFeedContainer = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const filteredChildren = Children.toArray(children).filter(
    (child) => !isValidElement(child) || child.type !== StoriesFeedFooter,
  );

  return (
    <div ref={ref} className={cn(className, "w-full h-full relative")} {...props}>
      <div className="flex flex-col justify-center h-full items-center">
        {filteredChildren}
      </div>
    </div>
  );
});

StoriesFeedContainer.displayName = "StoriesFeedContainer";

export const StoriesFeedFooter = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className="flex flex-col gap-5 p-4">{children}</div>
);

export const StoriesHeaderIcon = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className="absolute top-12" data-testid="stories-header-icon">
    {children}
  </div>
);

export const StoriesFeedContent = ({
  children,
  className,
}: ComponentPropsWithoutRef<"div">): JSX.Element => (
  <div className={cn("flex flex-col text-center font-medium p-4 gap-3", className)}>
    {children}
  </div>
);

export const StoriesFeedRestartButton = ({
  children,
  className,
}: ComponentPropsWithoutRef<"div">): JSX.Element => {
  const { setCurrentIndex } = useStoriesFeedContext();
  return (
    <CpsButtonText
      className={className}
      onClick={() => {
        setCurrentIndex && setCurrentIndex(0);
      }}
    >
      {children as string}
    </CpsButtonText>
  );
};

interface StoriesFeedShareButtonProps {
  isSecondary?: boolean;
  targetRef: React.RefObject<HTMLDivElement>;
}

export const StoriesFeedShareButton = ({
  isSecondary,
  targetRef,
}: StoriesFeedShareButtonProps): JSX.Element => {
  const { setIsStoryPaused } = useStoriesFeedContext();

  const handleShare = async (): Promise<void> => {
    if (!targetRef.current) return;

    try {
      const dataUrl = await toPng(targetRef.current);

      const fileName = `story-${Date.now()}.png`;
      const { uri } = await Filesystem.writeFile({
        path: fileName,
        data: dataUrl.replace(/^data:image\/png;base64,/, ""),
        directory: Directory.Cache,
      });

      const canShare = await CapacitorShare.canShare();

      setIsStoryPaused && setIsStoryPaused(true);

      if (canShare.value && iosOrAndroid) {
        await CapacitorShare.share({
          title: "Compartilhar Story",
          text: "Confira este Story!",
          url: uri,
        });
      } else {
        const blob = await toBlob(targetRef.current);

        if (!blob) {
          throw new Error("Failed to create blob from stories");
        }

        const file = new File([blob], "story.png", { type: "image/png" });

        await navigator.share({
          title: "Compartilhar Story",
          text: "Confira este Story!",
          files: [file],
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  const renderShareButton = (): JSX.Element => {
    const ButtonComponent = isSecondary ? Button : CpsButton;

    return (
      <ButtonComponent
        rounded
        Icon={Share}
        onClick={() => void handleShare()}
        id="stories-share-button"
      >
        Compartilhar
      </ButtonComponent>
    );
  };

  return <div className="absolute bottom-4 right-4 z-[1000]">{renderShareButton()}</div>;
};

StoriesFeedShareButton.displayName = "StoriesFeedShareButton";

interface StoryContentProps {
  action: Action;
  isStoryPaused: boolean;
  children: ReactNode;
}

const StoryContent = ({
  action,
  isStoryPaused,
  children,
}: StoryContentProps): JSX.Element => {
  const actionRef = useRef(action);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    const performAction = isStoryPaused ? "pause" : "play";
    actionRef.current(performAction);
  }, [isStoryPaused]);

  return <>{children}</>;
};

interface StoriesFeedWrapperProps extends Omit<ReactInstaStoriesProps, "stories"> {
  children: ReactNode | ReactNode[];
  defaultInterval?: number;
  showCloseButton?: boolean;
  open: boolean;
  onOpenChange: (show: boolean) => void;
  onStoriesCloseAction?: (index: number) => void;
}

export const StoriesFeedRoot = ({
  children,
  defaultInterval = 5000,
  showCloseButton,
  open,
  onOpenChange,
  onStoriesCloseAction,
  ...props
}: StoriesFeedWrapperProps): JSX.Element | null => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isStoryPaused, setIsStoryPaused] = useState(false);

  const bind = useDrag(
    ({ movement: [, y], direction: [, dirY], swipe: [, swipeY], last }) => {
      if ((swipeY === 1 || dirY === 1 || y > 100) && last) {
        storiesCloseAction();
      }
    },
    {
      axis: "y",
      filterTaps: true,
    },
  );

  const seeMoreContent = useCallback(
    ({ story, action }: StoryProps, child: ReactNode) => (
      <WithSeeMore story={story} action={action}>
        {child}
      </WithSeeMore>
    ),
    [],
  );

  const defaultStoryContent = useCallback(
    (action: Action, child: ReactNode) => (
      <StoryContent action={action} isStoryPaused={isStoryPaused}>
        {child}
      </StoryContent>
    ),
    [isStoryPaused],
  );

  const getSeeMoreComponent = (child: ReactNode): ReactNode | undefined => {
    if (!isValidElement(child)) return undefined;

    return Children.toArray(
      (child as ReactElement<{ children: ReactNode }>).props.children,
    ).find(
      (grandChild) => isValidElement(grandChild) && grandChild.type === StoriesFeedFooter,
    );
  };

  const stories = Children.toArray(children).map((child) => {
    const seeMoreComponent = getSeeMoreComponent(child);

    if (isValidElement(seeMoreComponent)) {
      return {
        content: (storyProps: StoryProps) => seeMoreContent(storyProps, child),
        seeMoreCollapsed: (): ReactNode => seeMoreComponent,
        seeMore: () => SeeMoreComponent,
      };
    }

    return {
      content: ({ action }: { action: Action }) => defaultStoryContent(action, child),
    };
  });

  const storiesCloseAction = (): void => {
    onStoriesCloseAction?.(currentIndex);
    closeStories();
  };

  const closeStories = (): void => {
    setCurrentIndex(0);
    onOpenChange(false);
  };

  const contextValue = useMemo(
    () => ({ setCurrentIndex, setIsStoryPaused }),
    [setCurrentIndex, setIsStoryPaused],
  );

  if (!open) {
    return null;
  }

  return (
    <div
      className="absolute top-0 left-0 w-full h-full stories-feed touch-none"
      {...bind()}
    >
      <div className="relative w-full h-full">
        <StoriesFeedContext.Provider value={contextValue}>
          {showCloseButton ? (
            <button
              type="button"
              className="fill-white absolute top-6 right-2 z-[1000]"
              aria-label="Fechar"
              onClick={storiesCloseAction}
            >
              <X size={24} />
            </button>
          ) : null}

          <div className="h-full w-full">
            <InstaStories
              {...props}
              preloadCount={1}
              defaultInterval={defaultInterval}
              width="100%"
              height="100%"
              onAllStoriesEnd={closeStories}
              currentIndex={currentIndex}
              onStoryEnd={(index: number) => {
                if (index === stories.length - 1) return;

                setIsStoryPaused(false);
                setCurrentIndex(index + 1);
              }}
              onPrevious={() => {
                if (currentIndex < 1) return;

                setIsStoryPaused(false);
                setCurrentIndex(currentIndex - 1);

                props.onPrevious?.(currentIndex - 1);
              }}
              onNext={() => {
                setIsStoryPaused(false);
                setCurrentIndex(currentIndex + 1);

                props.onNext?.(currentIndex + 1);
              }}
              stories={stories as Story[]}
            />
          </div>
        </StoriesFeedContext.Provider>
      </div>
    </div>
  );
};

const SeeMoreComponent = (): JSX.Element => <div />;

interface StoriesFeedContextProps {
  setCurrentIndex?: (show: number) => void;
  setIsStoryPaused?: (isPaused: boolean) => void;
}

const StoriesFeedContext = createContext<StoriesFeedContextProps>({});

const useStoriesFeedContext = (): StoriesFeedContextProps =>
  useContext(StoriesFeedContext);
