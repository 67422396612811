import { type ReactNode } from "react";

interface CustomerContactVariationBadgeProps {
  children: ReactNode;
}

export const CustomerContactVariationBadge = ({
  children,
}: CustomerContactVariationBadgeProps): JSX.Element | null => {
  const variationValue = Number(children);
  const badgeBackground = variationValue > 0 ? "bg-success-100" : "bg-danger-100";
  const variationSign = variationValue > 0 ? "+" : "";
  return variationValue === 0 ? null : (
    <span
      className={`${badgeBackground} rounded-70 px-2 py-1 my-[5.5px]`}
      data-testid="customer-contact-variation-badge"
    >
      <p className="font-medium text-[10px]">
        {variationSign} {children}%
      </p>
    </span>
  );
};
