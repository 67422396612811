import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type CustomerContactReasonFragmentFragment } from "@repo/graphql-types/graphql";
import { CustomerContactSectionHeader } from "@/components/customer-contact-overview/customer-contact-section-header";
import { CustomerContactDrawer } from "@/components/customer-contact-overview/customer-contact-drawer";
import { CustomerContactsOverviewEmptyState } from "@/components/customer-contact-overview/customer-contact-overview-empty-state";
import { CustomerContactsTotalNumber } from "@/components/customer-contact-overview/customer-contacts-total-number";
import {
  ChartRoot,
  ChartPieContainer,
  ChartPieItem,
  ChartPieLegend,
} from "@/components/charts";
import {
  type ContactReason,
  contactReasonMap,
} from "@/enums/customer-contact/contact-reason";
import { trackEvent } from "@/lib/tracking";

export const CustomerContactReasonFragment = graphql(/* GraphQL */ `
  fragment CustomerContactReasonFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
    totalCustomerContacts
    period
    mainReasonsOccurrences {
      key
      total
    }
  }
`);

interface CustomerContactReasonProps {
  data: FragmentType<typeof CustomerContactReasonFragment>[];
  selectedMonth: string;
}

export const CustomerContactReason = ({
  data,
  selectedMonth,
}: CustomerContactReasonProps): JSX.Element => {
  const fragmentData = useFragment(CustomerContactReasonFragment, data);

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/livance-via/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactReasonDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Motivos de contato",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactReasonDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div className="my-8 flex flex-col gap-3">
      <CustomerContactSectionHeader onOpenChange={onOpenChange}>
        Motivos de contato
      </CustomerContactSectionHeader>
      {data.length === 0 ||
      !currentMonthData ||
      currentMonthData.mainReasonsOccurrences.length === 0 ||
      currentMonthData.mainReasonsOccurrences.every((x) => x.total === 0) ? (
        <CustomerContactsOverviewEmptyState />
      ) : (
        <>
          <CustomerContactsTotalNumber total={currentMonthData.totalCustomerContacts} />
          <CustomerContactReasonPieChart reasonChartData={currentMonthData} />
        </>
      )}
      <CustomerContactDrawer
        open={Boolean(searchParams.showCustomerContactReasonDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Motivos de contato"
      >
        <p>Classificação dos contatos recebidos: </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Novo agendamento: </span>
            <span>Contatos realizados com a intenção de marcar uma nova consulta.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Tratar de consulta marcada: </span>
            <span>Contatos referentes a consultas que já estavam agendadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Dúvida pós-consulta: </span>
            <span>Contatos feitos após uma consulta ter sido realizada.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Informações diversas: </span>
            <span>Agrupamento de motivos diversos.</span>
          </li>
        </ul>
      </CustomerContactDrawer>
    </div>
  );
};

interface CustomerContactReasonPieChartProps {
  reasonChartData: CustomerContactReasonFragmentFragment;
}

export const CustomerContactReasonPieChart = ({
  reasonChartData,
}: CustomerContactReasonPieChartProps): JSX.Element => {
  const contactsWithSelectedReason = reasonChartData.mainReasonsOccurrences.reduce(
    (sum, reason) => sum + reason.total,
    0,
  );

  const contactsWithoutReason =
    reasonChartData.totalCustomerContacts - contactsWithSelectedReason;

  const chartRootData = reasonChartData.mainReasonsOccurrences.map((reason) => ({
    value: reason.total,
    contactReason: contactReasonMap[reason.key as ContactReason],
  }));

  if (contactsWithoutReason > 0) {
    chartRootData.push({
      value: contactsWithoutReason,
      contactReason: "Motivo não informado",
    });
  }

  return (
    <div data-testid="customer-contact-reason-chart">
      <ChartRoot data={chartRootData}>
        <ChartPieContainer
          dataKey="value"
          dataLabelField="contactReason"
          height={chartRootData.length > 4 ? 400 : 328}
        >
          <ChartPieItem fill="#00658A" itemLabel="Novos agendamentos" />
          <ChartPieItem fill="#003C52" itemLabel="Informações diversas" />
          <ChartPieItem fill="#72ABBC" itemLabel="Tratar de consulta marcada" />
          <ChartPieItem fill="#ABD3DD" itemLabel="Dúvidas pós-consulta" />
          {contactsWithoutReason > 0 ? (
            <ChartPieItem fill="#ABD3DD" itemLabel="Motivo não informado" />
          ) : null}
          <ChartPieLegend />
        </ChartPieContainer>
      </ChartRoot>
    </div>
  );
};
