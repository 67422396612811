import { cn, type AlignProps } from "@repo/lib";
import { Link, type ReactNode, type LinkProps } from "@tanstack/react-router";
import { type ComponentPropsWithoutRef, type HTMLAttributes } from "react";

interface ItemProps extends ComponentPropsWithoutRef<"li"> {
  children: React.ReactElement<AlignProps> | React.ReactElement<AlignProps>[];
  linkProps?: LinkProps;
  externalRoute?: string;
}

const ItemStructure = ({
  children,
}: {
  children: React.ReactElement<AlignProps> | React.ReactElement<AlignProps>[];
}): JSX.Element => {
  const itemElements = Array.isArray(children) ? children : [children];

  const endItems = itemElements.filter(
    (child) => child.props["data-align"] === "end" || child.type === ItemContentEnd,
  );

  const startItems = itemElements.filter(
    (child) =>
      (!child.props["data-align"] && !endItems.includes(child)) ||
      child.props["data-align"] === "start" ||
      child.type === ItemContentStart,
  );

  return (
    <>
      <div
        data-testid="startItems"
        className={cn("flex flex-row gap-4 w-full items-center")}
      >
        {startItems}
      </div>
      <div data-testid="endItems" className={cn("flex flex-row gap-4 items-center")}>
        {endItems}
      </div>
    </>
  );
};

export const ItemContentStart = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  if (!children) {
    return null;
  }

  return <div {...props}>{children}</div>;
};

export const ItemContentEnd = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  if (!children) {
    return null;
  }

  return <div {...props}>{children}</div>;
};

export const Item = ({
  children,
  linkProps,
  externalRoute,
  ...props
}: ItemProps): JSX.Element => {
  if (linkProps?.to ?? externalRoute) {
    const LinkComponent = linkProps?.to ? Link : "a";

    const linkComponentProps = linkProps?.to
      ? { ...linkProps }
      : { href: externalRoute, ...linkProps };

    return (
      <li className="py-6 w-full">
        <LinkComponent
          className={cn("justify-between flex items-stretch")}
          {...linkComponentProps}
        >
          <ItemStructure>{children}</ItemStructure>
        </LinkComponent>
      </li>
    );
  }

  return (
    <li {...props} className="py-6 w-full">
      <div className="justify-between items-stretch flex gap-4">
        <ItemStructure>{children}</ItemStructure>
      </div>
    </li>
  );
};

interface ItemContentProps {
  children: JSX.Element[] | JSX.Element | null;
}

export const ItemContent = ({ children }: ItemContentProps): JSX.Element => {
  return <div className="flex flex-col">{children}</div>;
};

interface ItemMainContentProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
}

export const ItemMainContent = ({
  className,
  children,
  ...props
}: ItemMainContentProps): JSX.Element => {
  return (
    <div {...props} className={cn("font-medium text-lg", className)}>
      {children}
    </div>
  );
};

export interface Info {
  label: string;
  value: string;
}

interface InfoItemProps {
  itemInfo: Info;
}

export const InfoItem = ({ itemInfo }: InfoItemProps): JSX.Element => {
  return (
    <Item>
      <p className="text-sm text-gray-600 font-normal">{itemInfo.label}</p>
      <p className="text-sm text-gray-800 font-medium truncate" data-align="end">
        {itemInfo.value}
      </p>
    </Item>
  );
};
